/**/
/**/
/*
The sections Header(Header menu, Top nav), Footer, Slider, Services,
News(Blogs), Testimonials will be CSS in the same area. The individual 
sections will have separate CSS in each Home area. If you want to find 
the CSS position of a certain section, look at its HTML file structure 
first (look at their class, tag name, ...), then you can search by 
title in the table of contents (Press Crtl + F to search).
*/
/**/
/**/
/* 
*
*** Table of contents:
-- Main color
-- Reset CSS
-- container
-- Main class used in Finatex

-- Header(Top nav, menu)
---- Header top nav main
---- Responsive Top Nav
---- Header menu main
---- Header menu Home2
---- Header menu Home3
---- Header menu Home4
---- Header menu Home5
---- Header menu Home6
---- Change active link of header menu subpages
---- Responsive Header
---- Mobile Menu Humburger

-- Slider
---- Section Slider Homepage Main
---- Section Slider Subpage Main (About, Contact, FAQs, ...)

-- Services
---- Services Sections (Home1, home2, ...)
---- Service Detail Page (service-detail.html) 

-- Case Studies
---- Case Studies Page - Style 1 (case-studies-one.html)
---- Case Studies Page - Style 2 (case-studies-two.html)
---- Section Case Studies
---- Case Studies Detail (case-studies-detail.html)

-- Section Testimonial
---- Section Testimonial Home2
---- Section Testimonial Home3
---- Section Testimonial Home6

-- Blogs
---- Blog List
---- Blog Masonry (.blog-list.style-masonry)
---- Blog Sections (Change color when hover)
------ Lastest News - Home 3
------ Lastest Blogs - Home 6
---- Blog Item (Shared)
---- Blog Detail - Style 1 (blog-detail-one.html)
---- Blog Detail - Style 2 (blog-detail-two.html)

-- Home1 Sections
---- Payment Gateway
------ Section Payment Gateway Services (first) Home1
------ Section Payment Gateway Services (second) Home1
---- Section Benefit(Case Studies) Home1
---- Section FAQs (Frequently Asked questions) Home1
---- Section Form request (Request a free call back) Home1

-- Home2 Sections
---- Section About us Home2(Company Overview)
---- Section counter (count number) Home2
---- Section Our Project Home2
---- Section Benefit (Form Request) Home2

-- Home3 Sections
---- Section Features Home3
---- Section Benefit(Form Request) Home3

-- Home4 Sections
---- Section Counter Home4
---- Section Our Pricing Home4
---- Section Our Blogs Home4
---- Section Form Resquest Home4

-- Home5 Sections
---- Section About Home5
---- Section Card(One of the Most Trusted Wealth Management Companies) Home5
---- Section Projects Home5
---- Section Form Request Home5

-- Home6 Sections
---- Section Video About Home6
---- Section About (People Trust Us) Home6
---- Section Pricing Home6
---- Section How we work Home6

-- Contact Us Page
---- Form contact
---- Section Our Team (Shared with Home5)

-- Shared List Questions (FAQs)
-- Shared List Pricing main 
-- List partners page

-- Modal Video
-- Shared Animation Filter Item
-- Shared Layout some sections 
(case-studies-detail: (Final outcome of this project), home3: (Registering and Using Online Banking Services))
-- Shared section partners
-- Scroll To Top Button

-- Form contact main
-- Form contact Home6

-- CTA 
---- CTA Home2
---- CTA Home3
---- Section CTA: Take control of your financial future - Home4
---- Section CTA: Empower Your Finances with Cryptocurrency - Home5

-- Footer
-- Reduce font and spacing

*
*/
/* Main color */
:root {
  --on-surface: #121212;
  --secondary: #64666C;
  --line: #E5E5E5;
  --background: #F5F5F5;
  --surface: #F5F5F2;
  --blue: #2868D8;
  --critical: #EB4D4D;
  --success: #37CC8E;
  --primary: #2ED197;
  --orange: #EA603F;
  --white: #FFFFFF;
  --deep-purple: #15143B;
  --gradient: #5D47E2;
  --dark: #0F1E37;
  --grey: #3F4B5F;
  --dark-blue: #173363;
  --dark-purple: #130E38;
  --placehover: #B1B1B1;
  --yellow: #F2C94C;
}

/* 
*
*** Reset CSS: Start
*
*/
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: var(--on-surface);
  line-height: 26px;
  scroll-behavior: smooth;
}

#content {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

html {
  line-height: 1; /* 1 */
}

body {
  margin: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
}

hr {
  box-sizing: content-box; /* 1 */
  height: 1; /* 1 */
  overflow: visible; /* 2 */
}

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

li {
  list-style: none;
}

a {
  background-color: transparent;
  color: var(--on-surface);
  text-decoration: none;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

input {
  outline: none;
  border: 1px solid transparent;
}
input:focus {
  border: 1px solid var(--blue);
}

button,
select {
  /* 1 */
  text-transform: none;
}

button:focus,
select:focus {
  outline: 0;
  box-shadow: none;
}

input:focus {
  outline: 0;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px; /* 2 */
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

/* 
*
*** Reset CSS: End
*
*/
/**/
/**/
/* 
*
container: Start
*
*/
.container {
  max-width: 1322px;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1199.98px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}

/* 
*
*** container: End
*
*/
/**/
/**/
/* 
*
*** Main class used in Finatex: Start
*
*/
.icon-on-surface::before {
  color: var(--on-surface);
}

.icon-gradient::before {
  color: var(--gradient);
}

.icon-white::before {
  color: var(--white);
}

.select-arrow-none select {
  -webkit-appearance: none;
  outline: none;
  border: 1px solid transparent;
}
.select-arrow-none select:focus {
  border: 1px solid var(--blue);
}

.col-xl-2-5 {
  flex: 0 0 20%;
  max-width: 20%;
}

.text-on-surface {
  color: var(--on-surface);
}

.text-secondary {
  color: var(--secondary);
}

.text-line {
  color: var(--line);
}

.text-surface {
  color: var(--surface);
}

.text-blue {
  color: var(--blue);
}

.text-critical {
  color: var(--critical);
}

.text-orange {
  color: var(--orange);
}

.text-gradient {
  color: var(--gradient);
}

.text-white {
  color: var(--white);
}

.text-placehover {
  color: var(--placehover);
}

.text-yellow {
  color: var(--yellow);
}

.text-deep-purple {
  color: var(--deep-purple);
}

.bg-placehover {
  background-color: var(--placehover);
}

.bg-on-surface {
  background-color: var(--on-surface);
}

.bg-transparent {
  background-color: transparent;
}

.bg-line {
  background-color: var(--line);
}

.bg-background {
  background-color: var(--background);
}

.bg-surface {
  background-color: var(--surface);
}

.bg-blue {
  background-color: var(--blue);
}

.bg-success {
  background-color: var(--success);
}

.bg-orange {
  background-color: var(--orange);
}

.bg-white {
  background-color: var(--white);
}

.bg-deep-purple {
  background-color: var(--deep-purple);
}

.bg-gradient {
  background-color: var(--gradient);
}

.bg-gradient-blue {
  background: linear-gradient(136deg, #5473DC 0%, #8BF7FF 100%);
}

.bg-dark {
  background-color: var(--dark);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}

.bg-dark-purple {
  background-color: var(--dark-purple);
}

.bg-grey {
  background-color: var(--grey);
}

.heading1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 88px;
  text-transform: capitalize;
}

.heading2 {
  font-size: 56px;
  font-weight: 700;
  line-height: 68px;
  text-transform: capitalize;
}

.heading3 {
  font-size: 44px;
  font-weight: 700;
  line-height: 50px;
  text-transform: capitalize;
}

.heading4 {
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-transform: capitalize;
}

.heading5 {
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  text-transform: capitalize;
}

.heading6 {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
}

.heading7 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-transform: capitalize;
}

.body1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.body2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.body3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.text-button {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-transform: capitalize;
}

.text-button-small {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-transform: capitalize;
}

.text-button-uppercase {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.text-sub-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

.text-sub-heading2 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 2.8px;
  text-transform: uppercase;
}

.caption1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.caption2 {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.fs-60 {
  font-size: 60px;
}

.fs-52 {
  font-size: 52px;
}

.fs-48 {
  font-size: 48px;
}

.fs-42 {
  font-size: 42px;
}

.fs-36 {
  font-size: 36px;
}

.fs-32 {
  font-size: 32px;
}

.fs-28 {
  font-size: 28px;
}

.fs-24 {
  font-size: 24px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-36 {
  padding-bottom: 36px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-14 {
  padding-bottom: 14px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pt-100 {
  padding-top: 100px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-36 {
  padding-top: 36px;
}

.pt-32 {
  padding-top: 32px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-14 {
  padding-top: 14px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-4 {
  padding-top: 4px !important;
}

.mt-100 {
  margin-top: 100px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 4px !important;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-minus-1 {
  margin-bottom: -1px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-28 {
  margin-left: 28px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-4 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.pl-100 {
  padding-left: 100px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-64 {
  padding-left: 64px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-36 {
  padding-left: 36px;
}

.pl-32 {
  padding-left: 32px;
}

.pl-28 {
  padding-left: 28px;
}

.pl-24 {
  padding-left: 24px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-12 {
  padding-left: 12px;
}

.pl-9 {
  padding-left: 9px;
}

.pl-8 {
  padding-left: 8px;
}

.pl-4 {
  padding-left: 4px !important;
}

.pr-80 {
  padding-right: 80px;
}

.pr-64 {
  padding-right: 64px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-36 {
  padding-right: 36px;
}

.pr-32 {
  padding-right: 32px;
}

.pr-28 {
  padding-right: 28px;
}

.pr-24 {
  padding-right: 24px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-16 {
  padding-right: 16px;
}

.pr-12 {
  padding-right: 12px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-4 {
  padding-right: 4px !important;
}

.p-40 {
  padding: 40px;
}

.p-36 {
  padding: 36px;
}

.p-32 {
  padding: 32px;
}

.p-28 {
  padding: 28px;
}

.p-24 {
  padding: 24px;
}

.p-16 {
  padding: 16px;
}

.p-14 {
  padding: 14px;
}

.p-12 {
  padding: 12px;
}

.p-8 {
  padding: 8px;
}

.p-4 {
  padding: 4px !important;
}

.hidden {
  visibility: hidden;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-item-center {
  display: flex;
  align-items: center;
}

.flex-columns-item-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-columns-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-columns-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-start {
  align-items: flex-start;
}

.d-flex {
  display: flex;
}

.row-gap-60 {
  row-gap: 60px;
}

.row-gap-40 {
  row-gap: 40px;
}

.row-gap-32 {
  row-gap: 32px;
}

.row-gap-24 {
  row-gap: 24px;
}

.row-gap-20 {
  row-gap: 20px;
}

.row-gap-12 {
  row-gap: 12px;
}

.row-gap-6 {
  row-gap: 6px;
}

.row-gap-4 {
  row-gap: 4px;
}

.gap-80 {
  gap: 80px;
}

.gap-60 {
  gap: 60px;
}

.gap-40 {
  gap: 40px;
}

.gap-30 {
  gap: 30px;
}

.gap-24 {
  gap: 24px;
}

.gap-20 {
  gap: 20px;
}

.gap-16 {
  gap: 16px;
}

.gap-12 {
  gap: 12px;
}

.gap-10 {
  gap: 10px;
}

.gap-8 {
  gap: 8px;
}

.gap-4 {
  gap: 4px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-upper {
  text-transform: uppercase;
}

.w-350 {
  width: 350px;
}

.w-120 {
  width: 120px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-60 {
  width: 60px;
}

.h-60 {
  height: 60px;
}

.w-48 {
  width: 48px;
}

.h-48 {
  height: 48px;
}

.w-40 {
  width: 40px;
}

.h-40 {
  height: 40px;
}

.w-28 {
  width: 28px;
}

.h-28 {
  height: 28px;
}

.w-24 {
  width: 24px;
}

.h-24 {
  height: 24px;
}

.w-1 {
  width: 1px;
}

.h-120 {
  height: 120px;
}

.h-88 {
  height: 88px;
}

.h-80 {
  height: 80px;
}

.h-44 {
  height: 44px;
}

.bora-100 {
  border-radius: 100px;
}

.bora-50 {
  border-radius: 50%;
}

.bora-48 {
  border-radius: 48px;
}

.bora-40 {
  border-radius: 40px;
}

.bora-24 {
  border-radius: 24px;
}

.bora-20 {
  border-radius: 20px;
}

.bora-16 {
  border-radius: 16px;
}

.bora-12 {
  border-radius: 12px;
}

.bora-8 {
  border-radius: 8px;
}

.bora-4 {
  border-radius: 4px;
}

.border-blue-1px {
  border: 1px solid var(--blue);
}

.border-blue-2px {
  border: 2px solid var(--blue);
}

.border-grey-1px {
  border: 1px solid var(--grey);
}

.border-grey-2px {
  border: 2px solid var(--grey);
}

.border-white-2px {
  border: 2px solid var(--white);
}

.border-transparent {
  border: 1px solid transparent;
}

.border-none {
  border: none;
}

.border-line-1px {
  border: 1px solid var(--line);
}

.border-line {
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}

.line-x {
  width: 100%;
  height: 1px;
  background-color: var(--line);
}

.line-y {
  height: 100%;
  width: 1px;
  background-color: var(--line);
}

.outline-none {
  outline: none;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-inline-flex {
  display: inline-flex;
}

.hidden {
  visibility: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.hover-underline:hover {
  text-decoration: underline;
}

.text-underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.hover-scale {
  transition: all ease 0.8s;
  cursor: pointer;
}
.hover-scale:hover {
  transform: scale(1.05);
}

.hover-button-black {
  transition: all ease 0.5s;
  cursor: pointer;
}
.hover-button-black:hover {
  background-color: var(--on-surface);
  color: white;
}

.hover-bg-white {
  transition: all ease 0.5s;
  cursor: pointer;
}
.hover-bg-white i {
  transition: all ease 0.5s;
}
.hover-bg-white:hover {
  background-color: var(--white);
  color: var(--on-surface);
}
.hover-bg-white:hover i {
  color: var(--on-surface);
}

.hover-bg-gradient {
  transition: all ease 0.5s;
  cursor: pointer;
}
.hover-bg-gradient:hover {
  background-color: var(--gradient) !important;
}

.hover-bg-success {
  transition: all ease 0.5s;
  cursor: pointer;
}
.hover-bg-success:hover {
  background-color: var(--success);
}

.hover-text-blue {
  transition: all ease 0.3s;
  cursor: pointer;
}
.hover-text-blue:hover {
  color: var(--blue);
}

.hover-text-success {
  transition: all ease 0.3s;
  cursor: pointer;
}
.hover-text-success:hover {
  color: var(--success);
}

.hover-text-orange {
  transition: all ease 0.3s;
  cursor: pointer;
}
.hover-text-orange:hover {
  color: var(--orange);
}

.hover-border-white {
  transition: all ease 0.3s;
  cursor: pointer;
}
.hover-border-white:hover {
  border: 1px solid var(--white);
  opacity: 0.9;
}

.hover-opacity:hover {
  opacity: 0.9;
}

.hover-bg-blue {
  transition: all ease 0.3s;
  cursor: pointer;
}
.hover-bg-blue:hover {
  background-color: var(--blue);
  color: var(--white);
}

.hover-border-blue {
  transition: all ease 0.3s;
  cursor: pointer;
  border: 2px solid transparent;
}
.hover-border-blue:hover {
  background-color: var(--white);
  color: var(--blue);
  border: 2px solid var(--blue);
}

.hover-box-shadow {
  transition: all ease 0.3s;
}
.hover-box-shadow:hover {
  box-shadow: 1px 5px 20px 0px rgba(37, 82, 91, 0.2);
}

.box-shadow {
  box-shadow: 0px 0px 5px 0px rgba(28, 36, 51, 0.15);
}

.box-shadow-none:hover {
  box-shadow: none;
}

.icon-on-surface::before {
  color: var(--on-surface);
}

.icon-white::before {
  color: var(--white);
}

@media (max-width: 1169.99px) {
  .column-reverse-xl {
    flex-direction: column-reverse;
  }
}

/* 
*
*** Main class used in Finatex: End
*
*/
/**/
/**/
/*
Header top nav main Home1: Start
*/
.top-nav.style-one .list-social .item i {
  margin-left: 2px;
}
.top-nav.style-one .list-social .item:nth-child(3) i, .top-nav.style-one .list-social .item:nth-child(4) i, .top-nav.style-one .list-social .item:nth-child(5) i {
  margin-top: 1px;
}
.top-nav.style-one .list-social .item:hover {
  background-color: var(--white);
}
.top-nav.style-one .list-social .item:hover i::before {
  color: var(--blue);
}

/*
Header top nav main Home1: End
*/
/*
Header top nav main Home2: Start
*/
.list-social.style-two .item:hover {
  background-color: var(--on-surface);
}
.list-social.style-two .item:hover i::before {
  color: var(--white);
}

/*
Header top nav main Home2: End
*/
/*
Header top nav main Home3: Start
*/
.style-home-three .header-menu .menu-right-block .list-social .item:hover {
  background-color: #fff;
}
.style-home-three .header-menu .menu-right-block .list-social .item:hover i::before {
  color: var(--gradient);
}
.style-home-three .header-menu .menu-right-block .list-social .item:nth-child(3) i {
  margin-left: 3px;
  margin-top: 1px;
}
.style-home-three .header-menu .menu-right-block .list-social .item:nth-child(4) i {
  margin-left: 2px;
}
.style-home-three .header-menu .menu-right-block .list-social .item:nth-child(5) i {
  margin-left: 2px;
}

/*
Header top nav main Home3: End
*/
/*
Header top nav main Home4: Start
*/
.top-nav.style-four .right-block .list-social .item:hover i::before {
  color: var(--success);
}
.top-nav.style-four .right-block .list-social .item:nth-child(4) i {
  margin-left: 1px;
}

/*
Header top nav main Home4: End
*/
/*
Header top nav main Home5: Start
*/
.style-home-five .top-nav.style-one {
  background-color: var(--on-surface);
}
.style-home-five .top-nav.style-one select {
  background-color: var(--on-surface);
}

/*
Header top nav main Home5: End
*/
/*
Header top nav main Home6: Start
*/
.style-home-six .top-nav.style-one {
  background-color: var(--on-surface);
}
.style-home-six .top-nav.style-one .list-social .item i {
  margin-left: 2px;
}
.style-home-six .top-nav.style-one .list-social .item:nth-child(3) i, .style-home-six .top-nav.style-one .list-social .item:nth-child(4) i, .style-home-six .top-nav.style-one .list-social .item:nth-child(5) i {
  margin-top: 1px;
}
.style-home-six .top-nav.style-one .list-social .item:hover {
  background-color: var(--white);
}
.style-home-six .top-nav.style-one .list-social .item:hover i::before {
  color: var(--orange);
}
.style-home-six .top-nav.style-one select {
  background-color: var(--on-surface);
}

/*
Header top nav main Home6: End
*/
/*
Responsive Top Nav: Start
*/
@media (max-width: 992px) {
  .top-nav .location {
    display: none;
  }
  .top-nav .mail {
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .top-nav .h-44 {
    height: 36px;
  }
  .top-nav .h-60 {
    height: 40px;
  }
  .top-nav .right-block {
    gap: 0;
  }
  .top-nav .right-block .call-block,
  .top-nav .right-block .line,
  .top-nav .right-block .list-social {
    display: none;
  }
}
@media (max-width: 576px) {
  .top-nav.style-four .left-block img {
    width: 120px;
  }
  .top-nav.style-four .right-block .flex-item-center .caption2 {
    display: none;
  }
}
/*
Responsive Top Nav: End
*/
/*
Header menu main: Start
*/
.header-menu {
  box-shadow: 0px 5px 25px rgba(58, 64, 67, 0.1);
  width: 100%;
}
.header-menu .menu-center-block .nav-item {
  padding: 0 12px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.header-menu .menu-center-block .nav-item .sub-nav {
  position: absolute;
  width: 260px;
  left: -10px;
  top: 100px;
  padding-top: 4px;
  padding-bottom: 8px;
  background-color: #fff;
  z-index: 5;
  box-shadow: 0px 5px 25px rgba(58, 64, 67, 0.1);
  opacity: 0;
}
.header-menu .menu-center-block .nav-item .sub-nav .sub-nav-item {
  padding: 16px 20px;
}
.header-menu .menu-center-block .nav-item .sub-nav .sub-nav-item a {
  position: relative;
  transition: all ease 0.3s;
  padding-top: 16px;
  padding-bottom: 16px;
}
.header-menu .menu-center-block .nav-item .sub-nav .sub-nav-item a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 51%;
  transform: translateY(-50%);
  width: 0;
  height: 2px;
  background-color: var(--blue);
  border-radius: 6px;
  transition: all ease 0.3s;
}
.header-menu .menu-center-block .nav-item .sub-nav .sub-nav-item:hover a {
  color: var(--blue);
  padding-left: 20px;
}
.header-menu .menu-center-block .nav-item .sub-nav .sub-nav-item:hover a::before {
  width: 12px;
}
.header-menu .menu-center-block .nav-item .sub-nav .sub-nav-item.active a {
  color: var(--blue);
  padding-left: 20px;
}
.header-menu .menu-center-block .nav-item .sub-nav .sub-nav-item.active a::before {
  width: 12px;
}
.header-menu .menu-center-block .nav-item:hover .nav-link {
  color: var(--blue);
}
.header-menu .menu-center-block .nav-item:hover .sub-nav {
  top: 80px;
  opacity: 1;
  visibility: visible;
  transition: all ease-in-out 0.3s;
}
.header-menu #menu-mobile-block {
  z-index: 100;
}
.header-menu #menu-mobile-block .sub-nav-mobile .sub-nav-item:first-child {
  border-top: 1px solid var(--line);
}
.header-menu #menu-mobile-block .sub-nav-mobile .sub-nav-item a {
  position: relative;
}
.header-menu #menu-mobile-block .sub-nav-mobile .sub-nav-item a::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1.2px;
  background: var(--blue);
}
.header-menu #menu-mobile-block .sub-nav-mobile .sub-nav-item:hover > a {
  color: var(--blue);
}
.header-menu #menu-mobile-block .sub-nav-mobile .sub-nav-item.active a {
  color: var(--blue);
  padding-left: 16px;
}
.header-menu #menu-mobile-block .sub-nav-mobile .sub-nav-item.active a::before {
  width: 10px;
}

/*
Header menu main: End
*/
/*
Header menu Home2: Start
*/
.style-home-two {
  display: unset;
}
.style-home-two .home .sub-nav li:nth-child(2) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-home-two .home .sub-nav li:nth-child(2) a::before {
  width: 12px !important;
}
.style-home-two .home .sub-nav-mobile li:nth-child(3) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-home-two .home .sub-nav-mobile li:nth-child(3) a::before {
  width: 10px !important;
}

/*
Header menu Home2: End
*/
/*
Header menu Home3: Start
*/
.style-home-three .header-menu {
  background-color: var(--dark-purple);
  color: var(--white);
}
.style-home-three .header-menu .menu-left-block img:first-child {
  display: none;
}
.style-home-three .header-menu .menu-left-block img:last-child {
  display: block;
  width: 149px;
}
.style-home-three .header-menu .menu-center-block > ul .nav-item > a {
  color: var(--white);
}
.style-home-three .header-menu .menu-center-block > ul .nav-item:hover > a {
  color: var(--gradient);
}
.style-home-three .header-menu .menu-center-block > ul .nav-item .sub-nav li:hover a {
  color: var(--gradient);
}
.style-home-three .header-menu .menu-center-block > ul .nav-item .sub-nav li:hover a::before {
  background-color: var(--gradient);
}
.style-home-three .header-menu .sub-nav-mobile li:hover > a {
  color: var(--gradient) !important;
}
.style-home-three .header-menu .menu-right-block .text-button-small {
  display: block;
}
.style-home-three .header-menu .menu-right-block .list-social a i::before {
  color: var(--white);
}
.style-home-three .header-menu .menu-right-block .menu-humburger i {
  color: var(--white);
}
.style-home-three .home .sub-nav li:nth-child(2) a {
  color: var(--on-surface);
  padding-left: 0;
}
.style-home-three .home .sub-nav li:nth-child(2) a::before {
  width: 0 !important;
}
.style-home-three .home .sub-nav li:nth-child(2):hover a::before {
  width: 12px !important;
}
.style-home-three .home .sub-nav li:nth-child(3) a {
  color: var(--gradient);
  padding-left: 20px;
}
.style-home-three .home .sub-nav li:nth-child(3) a::before {
  width: 12px !important;
  background-color: var(--gradient) !important;
}
.style-home-three .home .sub-nav-mobile li:nth-child(3) a {
  color: var(--on-surface);
  padding-left: 0;
}
.style-home-three .home .sub-nav-mobile li:nth-child(3) a::before {
  width: 0 !important;
}
.style-home-three .home .sub-nav-mobile li:nth-child(4) a {
  color: var(--gradient);
  padding-left: 16px;
}
.style-home-three .home .sub-nav-mobile li:nth-child(4) a::before {
  width: 10px !important;
  background-color: var(--gradient) !important;
}

/*
Header menu Home3: End
*/
/*
Header menu Home4: Start
*/
.style-home-four {
  display: unset;
}
.style-home-four .header-menu .menu-center-block .nav-item:hover > a {
  color: var(--success);
}
.style-home-four .header-menu .menu-center-block .nav-item:hover .sub-nav {
  top: 88px;
}
.style-home-four .header-menu .menu-center-block .nav-item:first-child {
  padding-left: 0;
}
.style-home-four .header-menu .menu-center-block .nav-item .sub-nav li:hover > a {
  color: var(--success) !important;
}
.style-home-four .header-menu .menu-center-block .nav-item .sub-nav li:hover > a::before {
  background-color: var(--success) !important;
}
.style-home-four .sub-nav-mobile li:hover > a {
  color: var(--success) !important;
}
.style-home-four .home .sub-nav li:hover > a {
  color: var(--success) !important;
}
.style-home-four .home .sub-nav li:hover > a::before {
  background-color: var(--success) !important;
}
.style-home-four .home .sub-nav li:nth-child(4) a {
  color: var(--success);
  padding-left: 20px;
}
.style-home-four .home .sub-nav li:nth-child(4) a::before {
  width: 12px !important;
  background-color: var(--success) !important;
}
.style-home-four .home .sub-nav-mobile li:nth-child(5) a {
  color: var(--success);
  padding-left: 16px;
}
.style-home-four .home .sub-nav-mobile li:nth-child(5) a::before {
  width: 10px !important;
  background-color: var(--success) !important;
}

/*
Header menu Home4: End
*/
/*
Header menu Home5: Start
*/
.style-home-five {
  display: unset;
}
.style-home-five .home .sub-nav li:nth-child(5) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-home-five .home .sub-nav li:nth-child(5) a::before {
  width: 12px !important;
}
.style-home-five .home .sub-nav-mobile li:nth-child(6) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-home-five .home .sub-nav-mobile li:nth-child(6) a::before {
  width: 10px !important;
}
.style-home-five .menu-right-block .icon-call i::before {
  color: var(--on-surface);
}

/*
Header menu Home5: End
*/
/*
Header menu Home6: Start
*/
.style-home-six {
  display: unset;
}
.style-home-six .home .sub-nav li:nth-child(6) a {
  color: var(--orange);
  padding-left: 20px;
}
.style-home-six .home .sub-nav li:nth-child(6) a::before {
  width: 12px !important;
  background-color: var(--orange) !important;
}
.style-home-six .home .sub-nav-mobile li:nth-child(7) a {
  color: var(--orange);
  padding-left: 16px;
}
.style-home-six .home .sub-nav-mobile li:nth-child(7) a::before {
  width: 10px !important;
  background-color: var(--orange) !important;
}
.style-home-six .header-menu.style-six #menu-mobile-block {
  border-top: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all ease 0.5s;
  z-index: -1;
}
.style-home-six .header-menu.style-six #menu-mobile-block .menu-mobile-main {
  bottom: 0;
  left: -75%;
  width: 75%;
  height: auto;
  max-height: unset;
}
.style-home-six .header-menu.style-six #menu-mobile-block .menu-mobile-main .sub-nav-mobile .sub-nav-item:hover > a {
  color: var(--orange);
}
.style-home-six .header-menu.style-six #menu-mobile-block .close-block {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
  cursor: pointer;
}
.style-home-six .header-menu.style-six #menu-mobile-block .close-block i {
  font-size: 14px;
  margin-left: 1px;
}
.style-home-six .header-menu.style-six #menu-mobile-block .close-block:hover {
  background-color: var(--white);
}
.style-home-six .header-menu.style-six #menu-mobile-block.open {
  z-index: 100;
  opacity: 1;
  visibility: visible;
}
.style-home-six .header-menu.style-six #menu-mobile-block.open .menu-mobile-main {
  left: 0;
}
.style-home-six .header-menu.style-six .container .header-main {
  position: relative;
}
.style-home-six .header-menu.style-six .container .header-main .menu-header {
  background-color: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(29.5px);
  position: absolute;
  z-index: 10;
  border-radius: 80px;
  padding: 0 24px;
  width: 100%;
  top: 21px;
}
.style-home-six .header-menu.style-six .container .header-main .menu-header .menu-nav > li {
  padding: 27px 12px;
}
.style-home-six .header-menu.style-six .container .header-main .menu-header .menu-nav > li:hover > a {
  color: var(--orange) !important;
}
.style-home-six .header-menu.style-six .container .header-main .menu-header .menu-nav > li .sub-nav li:hover > a {
  color: var(--orange) !important;
}
.style-home-six .header-menu.style-six .container .header-main .menu-header .menu-nav > li .sub-nav li:hover > a::before {
  background-color: var(--orange) !important;
}
@media (max-width: 1169.99px) {
  .style-home-six .header-menu.style-six .container .header-main .menu-header .menu-nav > li {
    padding: 27px 6px;
  }
}
@media (max-width: 1023.99px) {
  .style-home-six .header-menu.style-six .container .header-main .menu-header {
    padding: 14px 24px;
  }
  .style-home-six .header-menu.style-six .container .header-main .menu-header .menu-right-block a {
    display: none;
  }
}
@media (max-width: 575.99px) {
  .style-home-six .header-menu.style-six .container .header-main .menu-header {
    padding: 10px 16px;
  }
  .style-home-six .header-menu.style-six .container .header-main .menu-header .menu-left-block img {
    width: 110px;
    display: block;
  }
  .style-home-six .header-menu.style-six .container .header-main .menu-header .menu-right-block .menu-humburger {
    display: flex;
  }
  .style-home-six .header-menu.style-six .container .header-main .menu-header .menu-right-block .menu-humburger i {
    font-size: 20px;
  }
}

/*
 Header menu Home6: End
*/
/*
Change active link of header menu subpages: Start
*/
.style-about-one .about .sub-nav li:first-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-about-one .about .sub-nav li:first-child a::before {
  width: 12px !important;
}
.style-about-one .about .sub-nav-mobile li:nth-child(2) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-about-one .about .sub-nav-mobile li:nth-child(2) a::before {
  width: 10px !important;
}

.style-about-two .about .sub-nav li:last-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-about-two .about .sub-nav li:last-child a::before {
  width: 12px !important;
}
.style-about-two .about .sub-nav-mobile li:nth-child(3) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-about-two .about .sub-nav-mobile li:nth-child(3) a::before {
  width: 10px !important;
}

.style-service-sidetab .services .sub-nav li:first-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-service-sidetab .services .sub-nav li:first-child a::before {
  width: 12px !important;
}
.style-service-sidetab .services .sub-nav-mobile li:nth-child(2) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-service-sidetab .services .sub-nav-mobile li:nth-child(2) a::before {
  width: 10px !important;
}

.style-service-tab-style .services .sub-nav li:nth-child(2) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-service-tab-style .services .sub-nav li:nth-child(2) a::before {
  width: 12px !important;
}
.style-service-tab-style .services .sub-nav-mobile li:nth-child(3) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-service-tab-style .services .sub-nav-mobile li:nth-child(3) a::before {
  width: 10px !important;
}

.style-service-detail .services .sub-nav li:last-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-service-detail .services .sub-nav li:last-child a::before {
  width: 12px !important;
}
.style-service-detail .services .sub-nav-mobile li:nth-child(4) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-service-detail .services .sub-nav-mobile li:nth-child(4) a::before {
  width: 10px !important;
}

.style-case-one .case .sub-nav li:first-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-case-one .case .sub-nav li:first-child a::before {
  width: 12px !important;
}
.style-case-one .case .sub-nav-mobile li:nth-child(2) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-case-one .case .sub-nav-mobile li:nth-child(2) a::before {
  width: 10px !important;
}

.style-case-two .case .sub-nav li:nth-child(2) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-case-two .case .sub-nav li:nth-child(2) a::before {
  width: 12px !important;
}
.style-case-two .case .sub-nav-mobile li:nth-child(3) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-case-two .case .sub-nav-mobile li:nth-child(3) a::before {
  width: 10px !important;
}

.style-case-detail .case .sub-nav li:last-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-case-detail .case .sub-nav li:last-child a::before {
  width: 12px !important;
}
.style-case-detail .case .sub-nav-mobile li:nth-child(4) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-case-detail .case .sub-nav-mobile li:nth-child(4) a::before {
  width: 10px !important;
}

.style-contact-one .contact .sub-nav li:first-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-contact-one .contact .sub-nav li:first-child a::before {
  width: 12px !important;
}
.style-contact-one .contact .sub-nav-mobile li:nth-child(2) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-contact-one .contact .sub-nav-mobile li:nth-child(2) a::before {
  width: 10px !important;
}

.style-contact-two .contact .sub-nav li:last-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-contact-two .contact .sub-nav li:last-child a::before {
  width: 12px !important;
}
.style-contact-two .contact .sub-nav-mobile li:nth-child(3) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-contact-two .contact .sub-nav-mobile li:nth-child(3) a::before {
  width: 10px !important;
}

.style-faqs .pages .sub-nav li:first-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-faqs .pages .sub-nav li:first-child a::before {
  width: 12px !important;
}
.style-faqs .pages .sub-nav-mobile li:nth-child(2) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-faqs .pages .sub-nav-mobile li:nth-child(2) a::before {
  width: 10px !important;
}

.style-pricing .pages .sub-nav li:nth-child(2) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-pricing .pages .sub-nav li:nth-child(2) a::before {
  width: 12px !important;
}
.style-pricing .pages .sub-nav-mobile li:nth-child(3) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-pricing .pages .sub-nav-mobile li:nth-child(3) a::before {
  width: 10px !important;
}

.style-partners .pages .sub-nav li:last-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-partners .pages .sub-nav li:last-child a::before {
  width: 12px !important;
}
.style-partners .pages .sub-nav-mobile li:nth-child(4) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-partners .pages .sub-nav-mobile li:nth-child(4) a::before {
  width: 10px !important;
}

.style-blog-list-one .blog .sub-nav li:first-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-blog-list-one .blog .sub-nav li:first-child a::before {
  width: 12px !important;
}
.style-blog-list-one .blog .sub-nav-mobile li:nth-child(2) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-blog-list-one .blog .sub-nav-mobile li:nth-child(2) a::before {
  width: 10px !important;
}

.style-blog-list-two .blog .sub-nav li:nth-child(2) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-blog-list-two .blog .sub-nav li:nth-child(2) a::before {
  width: 12px !important;
}
.style-blog-list-two .blog .sub-nav-mobile li:nth-child(3) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-blog-list-two .blog .sub-nav-mobile li:nth-child(3) a::before {
  width: 10px !important;
}

.style-blog-grid .blog .sub-nav li:nth-child(3) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-blog-grid .blog .sub-nav li:nth-child(3) a::before {
  width: 12px !important;
}
.style-blog-grid .blog .sub-nav-mobile li:nth-child(4) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-blog-grid .blog .sub-nav-mobile li:nth-child(4) a::before {
  width: 10px !important;
}

.style-blog-masonry .blog .sub-nav li:nth-child(4) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-blog-masonry .blog .sub-nav li:nth-child(4) a::before {
  width: 12px !important;
}
.style-blog-masonry .blog .sub-nav-mobile li:nth-child(5) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-blog-masonry .blog .sub-nav-mobile li:nth-child(5) a::before {
  width: 10px !important;
}

.style-blog-detail-one .blog .sub-nav li:nth-child(5) a {
  color: var(--blue);
  padding-left: 20px;
}
.style-blog-detail-one .blog .sub-nav li:nth-child(5) a::before {
  width: 12px !important;
}
.style-blog-detail-one .blog .sub-nav-mobile li:nth-child(6) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-blog-detail-one .blog .sub-nav-mobile li:nth-child(6) a::before {
  width: 10px !important;
}

.style-blog-detail-two .blog .sub-nav li:last-child a {
  color: var(--blue);
  padding-left: 20px;
}
.style-blog-detail-two .blog .sub-nav li:last-child a::before {
  width: 12px !important;
}
.style-blog-detail-two .blog .sub-nav-mobile li:nth-child(7) a {
  color: var(--blue);
  padding-left: 16px;
}
.style-blog-detail-two .blog .sub-nav-mobile li:nth-child(7) a::before {
  width: 10px !important;
}

/*
Change active link of header menu subpages: End
*/
/*
Responsive Header: Start
*/
#header {
  display: unset;
}

.header-menu {
  position: sticky;
  top: 0;
  z-index: 1000;
}

@media (max-width: 1169.99px) {
  .header-menu .menu-center-block .nav-item {
    padding: 0 8px;
  }
}
@media (max-width: 1169.99px) and (min-width: 1023.99px) {
  .style-home-two .header-menu .menu-right-block {
    gap: 6px;
  }
  .style-home-two .header-menu .menu-right-block .list-social {
    gap: 4px;
  }
  .style-home-two .header-menu .menu-right-block .list-social .item:last-child {
    display: none;
  }
}
@media (max-width: 1023.99px) {
  .header-menu .menu-center-block {
    display: none;
  }
  .header-menu .menu-right-block .menu-humburger {
    display: block;
    padding-left: 24px;
  }
  .header-menu .menu-right-block .menu-humburger i {
    font-size: 24px;
  }
  .header-menu.style-four .menu-humburger {
    display: block;
  }
  .header-menu.style-four .menu-humburger i {
    color: var(--white);
    font-size: 24px;
  }
}
@media (max-width: 576px) {
  .header-menu .h-80 {
    height: 60px;
  }
  .header-menu .h-80 .menu-left-block .menu-logo {
    width: 120px;
  }
  .header-menu .h-80 .menu-right-block .icon-call,
  .header-menu .h-80 .menu-right-block .text {
    display: none;
  }
  .style-home-two .header-menu .menu-right-block .text-button-small,
  .style-home-two .header-menu .menu-right-block .list-social {
    display: none;
  }
  .header-menu.style-four .h-88 {
    height: 60px;
  }
  .header-menu.style-four .menu-right-block .button-block {
    display: none;
  }
}
/*
Responsive Header: End
*/
/*
Mobile Menu Humburger: Start
*/
.header-menu #menu-mobile-block {
  position: relative;
}
.header-menu #menu-mobile-block .menu-mobile-main {
  transition: all ease-in-out 0.5s;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 0;
  z-index: 10;
  background-color: #fff;
}
.header-menu #menu-mobile-block .menu-mobile-main .menu-nav-mobile .nav-item-mobile .sub-nav-mobile {
  transition: all ease-in-out 0.5s;
  overflow: hidden;
  max-height: 0;
}
.header-menu #menu-mobile-block .menu-mobile-main .menu-nav-mobile .nav-item-mobile .sub-nav-mobile.open {
  max-height: 280px;
}
.header-menu #menu-mobile-block.open {
  border-top: 1px solid var(--line);
}
.header-menu #menu-mobile-block.open .menu-mobile-main {
  max-height: 320px;
}

/*
Mobile Menu Humburger: End
*/
/*
Section Slider Homepage Main: Start
*/
.slider-block {
  position: relative;
}
.slider-block .slider-main {
  position: relative;
}
.slider-block .slider-main .slider-item {
  position: relative;
  height: 1080px;
}
.slider-block .slider-main .slider-item .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.slider-block .slider-main .slider-item .bg-img img {
  object-fit: cover;
}
.slider-block .slider-main .slider-item .container {
  position: relative;
  height: 100%;
  z-index: 2;
}
.slider-block .slider-main .slider-item .container .text-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 15px;
}
@media (max-width: 500.99px) {
  .slider-block .slider-main .slider-item .text-content br {
    display: none;
  }
}
.slider-block .slider-main .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  gap: 16px;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
}
.slider-block .slider-main .slick-dots li {
  position: relative;
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.slider-block .slider-main .slick-dots li button {
  display: none;
}
.slider-block .slider-main .slick-dots li::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--secondary);
  transition: 0.5s;
}
.slider-block .slider-main .slick-dots li.slick-active {
  border: 1px solid var(--on-surface);
  background-color: #fff;
}
.slider-block .slider-main .slick-dots li.slick-active::after {
  background-color: var(--on-surface);
  border: 1px solid var(--on-surface);
}
@media (max-width: 2200.99px) {
  .slider-block .slider-main .slider-item {
    height: 940px;
  }
}
@media (max-width: 1919.99px) {
  .slider-block .slider-main .slider-item {
    height: 820px;
  }
}
@media (max-width: 1599.99px) {
  .slider-block .slider-main .slider-item {
    height: 640px;
  }
}
@media (max-width: 1169.99px) {
  .slider-block .slider-main .slider-item {
    height: 580px;
  }
}
@media (max-width: 767.99px) {
  .slider-block .slider-main .slider-item {
    height: 500px;
  }
}
@media (max-width: 575.99px) {
  .slider-block .slider-main .slider-item {
    height: 420px;
  }
  .slider-block .slider-main .slider-item .bg-img::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }
  .slider-block .slider-main .slider-item .container .text-content {
    top: 46%;
  }
  .slider-block .slider-main .slick-dots {
    bottom: 20px;
  }
}
.slider-block .prev-arrow,
.slider-block .next-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease 0.5s;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
}
.slider-block .prev-arrow:hover,
.slider-block .next-arrow:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.slider-block .prev-arrow {
  left: 20px;
}
.slider-block .next-arrow {
  right: 20px;
}
.slider-block:hover .prev-arrow,
.slider-block:hover .next-arrow {
  opacity: 1;
  visibility: visible;
}
.slider-block .prev-arrow.slick-disabled,
.slider-block .next-arrow.slick-disabled {
  display: none !important;
}
@media (max-width: 1023.99px) {
  .slider-block .prev-arrow,
  .slider-block .next-arrow {
    display: none !important;
  }
}
.slider-block.style-two .slider-main .slider-item {
  background-color: #F8F4EC;
  height: 840px;
}
.slider-block.style-two .slider-main .slider-item .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.slider-block.style-two .slider-main .slider-item .container .text-content {
  position: relative;
  top: unset;
  transform: unset;
}
.slider-block.style-two .slider-main .slider-item .container .text-content .button-block {
  height: 64px;
  width: 80%;
  position: relative;
}
.slider-block.style-two .slider-main .slider-item .container .text-content .button-block input {
  width: 100%;
  height: 100%;
  padding: 8px 190px 8px 24px;
  border-radius: 52px;
}
.slider-block.style-two .slider-main .slider-item .container .text-content .button-block a {
  position: absolute;
  top: 7px;
  right: 8px;
}
@media (max-width: 1919.99px) {
  .slider-block.style-two .slider-main .slider-item {
    height: 760px;
  }
}
@media (max-width: 1599.99px) {
  .slider-block.style-two .slider-main .slider-item {
    height: 640px;
  }
}
.slider-block.style-two .slider-img {
  position: relative;
}
.slider-block.style-two .slider-img .bg-main {
  height: 530px;
  padding-right: 50px;
}
.slider-block.style-two .slider-img > img:nth-child(2) {
  height: 195px;
  position: absolute;
  top: 100px;
  left: -55px;
  animation: moveY 10s ease infinite;
}
.slider-block.style-two .slider-img > img:last-child {
  height: 250px;
  position: absolute;
  bottom: 56px;
  right: 0px;
  animation: moveX 10s ease infinite;
}
.slider-block.style-two .slick-dots {
  display: none;
}
@media (max-width: 1023.99px) {
  .slider-block.style-two .slider-main .slider-item {
    height: 560px;
  }
  .slider-block.style-two .slider-main .slider-item .heading2 br {
    display: none;
  }
  .slider-block.style-two .slider-main .slider-item .slider-img {
    display: none;
  }
}
@media (max-width: 575.99px) {
  .slider-block.style-two .slider-main .slider-item {
    height: 380px;
  }
  .slider-block.style-two .slider-main .slider-item .container .text-content .button-block {
    width: 100%;
  }
  .slider-block.style-two .slider-main .slider-item .container .text-content .button-block input {
    height: 54px;
    border-radius: 32px;
  }
}
.slider-block.style-three {
  border-top: 1px solid #4b4b4b;
}
.slider-block.style-three .slider-main .slider-item {
  height: 690px;
  background-color: var(--dark-purple);
}
.slider-block.style-three .slider-main .slider-item .row {
  padding-top: 120px;
  position: relative;
  height: 100%;
}
.slider-block.style-three .slider-main .slider-item .row .slider-img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1300px) {
  .slider-block.style-three .slider-main .slider-item .row .heading2 br {
    display: none;
  }
}
@media (max-width: 991.99px) {
  .slider-block.style-three .slider-main .slider-item .row {
    padding-top: 60px;
    flex-direction: column;
  }
}
@media (max-width: 767.99px) {
  .slider-block.style-three .slider-main .slider-item .row {
    flex-wrap: unset;
    justify-content: unset;
    row-gap: 32px;
  }
  .slider-block.style-three .slider-main .slider-item .row .left-block,
  .slider-block.style-three .slider-main .slider-item .row .right-block {
    flex: unset;
    width: 100%;
    max-width: 100%;
  }
  .slider-block.style-three .slider-main .slider-item .row .count {
    justify-content: space-around;
  }
  .slider-block.style-three .slider-main .slider-item .row .count .left,
  .slider-block.style-three .slider-main .slider-item .row .count .right {
    text-align: center;
  }
}
.slider-block.style-three .slider-main .slick-dots {
  display: none;
}
@media (max-width: 1599.99px) {
  .slider-block.style-three .slider-main .slider-item {
    height: 620px;
  }
  .slider-block.style-three .slider-main .slider-item .row {
    height: 620px;
    padding-top: 80px;
  }
}
@media (max-width: 991.99px) {
  .slider-block.style-three .slider-main .slider-item {
    height: 600px;
  }
  .slider-block.style-three .slider-main .slider-item .row {
    height: 600px;
  }
}
@media (max-width: 767.99px) {
  .slider-block.style-three .slider-main .slider-item {
    height: 500px;
  }
  .slider-block.style-three .slider-main .slider-item .row {
    height: 500px;
    padding-top: 40px;
  }
}
@media (max-width: 400.99px) {
  .slider-block.style-three .slider-main .slider-item {
    height: 550px;
  }
  .slider-block.style-three .slider-main .slider-item .row {
    height: 550px;
    row-gap: 24px;
  }
}
.slider-block.style-four .text-content .button-block a:last-child {
  padding-top: 6px;
  padding-bottom: 6px;
  gap: 12px;
  transition: all ease 0.4s;
}
.slider-block.style-four .text-content .button-block a:last-child i {
  width: 38px;
  height: 38px;
  border: 2px solid var(--critical);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.4s;
}
.slider-block.style-four .text-content .button-block a:last-child:hover {
  color: var(--success);
  gap: 16px;
}
.slider-block.style-four .text-content .button-block a:last-child:hover i {
  background-color: var(--critical);
  color: var(--white);
}
@media (max-width: 575.99px) {
  .slider-block.style-four .text-content .button-block a:last-child {
    padding: 6px 0;
  }
}
.slider-block.style-four .slider-main .slider-item {
  height: 1060px;
}
@media (max-width: 2400.99px) {
  .slider-block.style-four .slider-main .slider-item {
    height: 980px;
  }
}
@media (max-width: 2200.99px) {
  .slider-block.style-four .slider-main .slider-item {
    height: 840px;
  }
}
@media (max-width: 1919.99px) {
  .slider-block.style-four .slider-main .slider-item {
    height: 740px;
  }
}
@media (max-width: 1599.99px) {
  .slider-block.style-four .slider-main .slider-item {
    height: 620px;
  }
}
@media (max-width: 768.99px) {
  .slider-block.style-four .slider-main .slider-item {
    height: 460px;
  }
}
@media (max-width: 575.99px) {
  .slider-block.style-four .text-content br {
    display: none;
  }
}
@media (max-width: 380.99px) {
  .slider-block.style-four .slider-main .button-block {
    flex-direction: column;
    align-items: flex-start;
  }
}
.slider-block.style-six .slider-main .slider-item {
  height: 1080px;
}
.slider-block.style-six .slider-main .slider-item .bg-img img {
  width: 110%;
}
.slider-block.style-six .slick-dots {
  display: none;
}
@media (max-width: 2200.99px) {
  .slider-block.style-six .slider-main .slider-item {
    height: 920px;
  }
}
@media (max-width: 1919.99px) {
  .slider-block.style-six .slider-main .slider-item {
    height: 820px;
  }
}
@media (max-width: 1599.99px) {
  .slider-block.style-six .slider-main .slider-item {
    height: 700px;
  }
}
@media (max-width: 1169.99px) {
  .slider-block.style-six .slider-main .slider-item {
    height: 640px;
  }
}
@media (max-width: 575.99px) {
  .slider-block.style-six .slider-main .slider-item {
    height: 460px;
  }
  .slider-block.style-six .slider-main .slider-item .bg-img::before {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  .slider-block.style-six .slider-main .slider-item .container .text-content {
    top: 55%;
    z-index: 2;
  }
}
@keyframes moveY {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveX {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}

/*
Section Slider Homepage Main: End
*/
/*
Section Slider Subpage Main: Start
*/
.slider-sub {
  width: 100%;
  height: 400px;
  position: relative;
}
.slider-sub .bg-img {
  width: 100%;
  height: 100%;
}
.slider-sub .bg-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-sub .container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.slider-sub .container .heading-nav {
  background-color: #808792;
  border-radius: 36px;
  padding: 6px 16px;
  display: inline-flex;
  align-items: center;
}
.slider-sub .container .heading-nav i {
  margin-top: 2px;
  font-size: 10px;
}
.slider-sub .container .text-nav {
  margin-top: 75px;
  width: 50%;
}
@media (max-width: 767.99px) {
  .slider-sub {
    height: 300px;
  }
  .slider-sub .container .text-nav {
    margin-top: 50px;
    width: 75%;
  }
}

/*
Section Slider Subpage Main: End
*/
/*
*** Service item main: Start
*/
.service-item .service-item-main i.ph-caret-right {
  margin-top: 2px;
}

.service-block .service-item {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.service-block .service-item .service-item-main .heading i.ph-arrow-up-right {
  position: absolute;
  top: 32px;
  right: -30px;
  visibility: hidden;
  cursor: pointer;
  transition: all ease 0.4s;
}
.service-block .service-item .service-item-main .number {
  opacity: 0.2;
  transition: all ease 0.4s;
}
.service-block .service-item .service-item-main .explore-block i {
  transition: all ease 0.3s;
}
.service-block .service-item .service-item-main .explore-block:hover i {
  margin-left: 4px;
}
.service-block .service-item:hover .service-item-main .heading7,
.service-block .service-item:hover .service-item-main .heading6 {
  color: var(--blue);
}
.service-block .service-item:hover .service-item-main .heading i.ph-arrow-up-right {
  right: 32px;
  visibility: visible;
}
.service-block .service-item:hover .service-item-main .number {
  opacity: 1;
}

/*
*** Service item main: End
*/
/* 
*** List Benefit Home3: Start
*/
.list-benefit-three {
  margin-top: 44px;
}
.list-benefit-three .row > .col-12 {
  position: relative;
}
.list-benefit-three .row > .col-12::after {
  position: absolute;
  content: "";
  top: 16px;
  bottom: 16px;
  right: 0;
  background-color: var(--line);
  width: 1px;
}
.list-benefit-three .row > .col-12:last-child::after {
  display: none;
}
@media (max-width: 767.99px) {
  .list-benefit-three .row > .col-12 .heading i {
    font-size: 32px;
  }
}
@media (max-width: 575.99px) {
  .list-benefit-three .row > .col-12::after {
    display: none;
  }
}
.list-benefit-three .service-item-main:hover .heading7 {
  color: var(--gradient);
}

/* 
*** List Benefit Home3: End
*/
/* 
Change icon color section Services Home3: Start
*/
.service-block.style-about-two.style-three .list-service .service-item:hover .heading6 {
  color: var(--gradient);
}

/* 
Change icon color section Services Home3: End
*/
/* 
*** Section Services About Us - Style 2: Start
*/
.service-block.style-about-two .list-service .service-item:hover .heading6 {
  color: var(--blue);
}
.service-block.style-about-two .list-service .service-item-main {
  text-align: center;
  gap: 8px;
}
.service-block.style-about-two .list-service .service-item-main .heading {
  flex-direction: column;
}
.service-block.style-about-two .list-service .service-item-main .heading .heading6 {
  font-size: 20px;
  line-height: 28px;
}
.service-block.style-about-two .list-service .service-item-main .heading .body3 {
  font-size: 14px;
  line-height: 22px;
}
@media (max-width: 1169.99px) {
  .service-block.style-about-two .list-service .row > div {
    border-right: none;
  }
}
@media (max-width: 767.99px) {
  .service-block.style-about-two {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .service-block.style-about-two .list-service {
    padding: 20px;
  }
}

/* 
*** Section Services About Us - Style 2: End
*/
.service-tab-style .list-nav .nav-item {
  transition: all ease 0.4s;
}
.service-tab-style .list-nav .active,
.service-tab-style .list-nav .nav-item:hover {
  background-color: var(--blue);
  color: var(--white);
  border-radius: 99px;
}

/*
*** Section Services - Style 2: End
*/
/*
*** Section Services Home2: Start
*/
@media (max-width: 1169.99px) {
  .service-block-two .col-xl-8 .pl-40 {
    padding-left: 0;
  }
}

/*
*** Section Services Home2: End
*/
/*
*** Section Services Home4: Start
*/
.service-style-four .service-block .list-service > div .service-item:hover .desc .heading7 {
  color: var(--success);
}
.service-style-four .service-block .list-service > div .service-item .read-block span,
.service-style-four .service-block .list-service > div .service-item .read-block i {
  color: var(--success);
  transition: all ease 0.3s;
}
.service-style-four .service-block .list-service > div:nth-child(5), .service-style-four .service-block .list-service > div:nth-child(6), .service-style-four .service-block .list-service > div:nth-child(7), .service-style-four .service-block .list-service > div:nth-child(8) {
  display: none;
}
.service-style-four .service-block .list-service > div .read-block:hover i {
  margin-left: 4px;
}

/*
*** Section Services Home4: End
*/
/*
*** Section Services Home5: Start
*/
.service-style-five .mt-100 {
  margin-top: 40px;
}
@media (max-width: 575.99px) {
  .service-style-five .heading3 br {
    display: none;
  }
}

/*
*** Section Services Home5: End
*/
/*
*** Section Services Home6: Start
*/
.service-block.style-six .list-service {
  row-gap: 80px;
  margin-top: 80px;
}
.service-block.style-six .list-service .main-item {
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: radial-gradient(50% 50% at 22.8% 0%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(6px);
  position: relative;
  padding: 72px 36px 28px;
  height: 100%;
  transition: all ease 0.5s;
  display: block;
}
.service-block.style-six .list-service .main-item .heading7 {
  transition: all ease 0.3s;
}
.service-block.style-six .list-service .main-item i {
  font-size: 48px;
  border-radius: 20px;
  padding: 26px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(249, 249, 249, 0) 100%);
  backdrop-filter: blur(17px);
  position: absolute;
  top: -50px;
  transition: background ease 0.5s;
}
.service-block.style-six .list-service .main-item:hover {
  margin-top: -10px;
}
.service-block.style-six .list-service .main-item:hover .heading7 {
  color: var(--orange);
}
.service-block.style-six .list-service .main-item:hover i {
  background: var(--orange);
  backdrop-filter: unset;
}
@media (max-width: 1023.99px) {
  .service-block.style-six .list-service .main-item:hover {
    margin-top: 0;
  }
}
@media (max-width: 767.99px) {
  .service-block.style-six .list-service {
    row-gap: 60px;
    margin-top: 60px;
  }
  .service-block.style-six .list-service .main-item {
    padding: 50px 32px 24px;
  }
  .service-block.style-six .list-service .main-item i {
    font-size: 32px;
    border-radius: 16px;
    padding: 16px;
    top: -30px;
  }
}

/*
*** Section Services Home6: End
*/
/*
Service Detail Page: Start
*/
.content-detail-block .container > .row > div:last-child .list-nav .nav-item.active, .content-detail-block .container > .row > div:last-child .list-nav .nav-item:hover {
  background-color: var(--surface);
}
.content-detail-block .container > .row > div:last-child .list-nav .nav-item.active .text-secondary, .content-detail-block .container > .row > div:last-child .list-nav .nav-item:hover .text-secondary {
  color: var(--on-surface);
}
.content-detail-block .container > .row > div:last-child .list-nav .nav-item.active i, .content-detail-block .container > .row > div:last-child .list-nav .nav-item:hover i {
  visibility: visible;
}
.content-detail-block .container > .row > div:last-child .ads-block {
  margin-top: 40px;
  width: 100%;
  position: relative;
}
.content-detail-block .container > .row > div:last-child .ads-block .bg-img {
  width: 100%;
}
.content-detail-block .container > .row > div:last-child .ads-block .bg-img img {
  width: 100%;
  z-index: -1;
}
.content-detail-block .container > .row > div:last-child .ads-block .text {
  position: absolute;
  left: 32px;
  top: 36px;
  bottom: 36px;
}
@media (max-width: 1169.99px) {
  .content-detail-block .container > .row > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .content-detail-block .container > .row > div:last-child .more-infor,
  .content-detail-block .container > .row > div:last-child .ads-block {
    width: 48%;
  }
  .content-detail-block .container > .row > div:last-child .ads-block {
    margin-top: 0;
    height: 100%;
  }
  .content-detail-block .container > .row > div:last-child .ads-block .bg-img,
  .content-detail-block .container > .row > div:last-child .ads-block img {
    height: 100%;
  }
  .content-detail-block .container > .row > div:last-child .ads-block .text {
    top: 25%;
    bottom: 25%;
  }
}
@media (max-width: 767.99px) {
  .content-detail-block .container > .row > div:last-child {
    flex-direction: column;
    row-gap: 32px;
  }
  .content-detail-block .container > .row > div:last-child .more-infor,
  .content-detail-block .container > .row > div:last-child .ads-block {
    width: 100%;
  }
}
@media (max-width: 575.99px) {
  .content-detail-block .container > .row > div:last-child .ads-block .text {
    top: 20%;
    bottom: 20%;
  }
}
@media (max-width: 1169.99px) {
  .content-detail-block .col-xl-9 .content-para {
    padding-right: 0;
  }
}

/*
Service Detail Page: End
*/
/*
*** Case Studies Page - Style 1: Start
*/
.case-studies-block.style-one .list-nav {
  flex-wrap: wrap;
}
.case-studies-block.style-one .list-nav .nav-item.active,
.case-studies-block.style-one .list-nav .nav-item:hover {
  background-color: var(--blue);
  border-radius: 100px;
  color: var(--white);
  transition: all linear 0.3s;
}
.case-studies-block.style-one .row {
  row-gap: 50px;
}
@media (max-width: 767.99px) {
  .case-studies-block.style-one .row {
    row-gap: 24px;
  }
}
.case-studies-block.style-one .row .item-main {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.case-studies-block.style-one .row .item-main .bg-img {
  position: relative;
}
.case-studies-block.style-one .row .item-main .bg-img img {
  transition: all linear 0.3s;
  cursor: pointer;
}
.case-studies-block.style-one .row .item-main .bg-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #121212;
  opacity: 0;
  transition: all ease 0.3s;
}
.case-studies-block.style-one .row .item-main .infor {
  margin-top: -30px;
  width: 80%;
  z-index: 1;
  box-shadow: 0px 5px 25px rgba(58, 64, 67, 0.1);
}
.case-studies-block.style-one .row .item-main .infor .category {
  letter-spacing: 1.2px;
}
.case-studies-block.style-one .row .item-main .infor .heading6 {
  transition: 0.3s;
}
.case-studies-block.style-one .row .item-main:hover .bg-img img {
  transform: scale(1.06);
}
.case-studies-block.style-one .row .item-main:hover .bg-img::after {
  opacity: 0.4;
}
.case-studies-block.style-one .row .item-main:hover .infor .heading6 {
  text-decoration: underline;
}

/*
*** Case Studies Page - Style 1: End
*/
/*
*** Case Studies Page - Style 2: Start
*/
.case-studies-block.style-two .list-nav {
  flex-wrap: wrap;
}
.case-studies-block.style-two .list-nav .nav-item.active,
.case-studies-block.style-two .list-nav .nav-item:hover {
  background-color: var(--blue);
  border-radius: 100px;
  color: var(--white);
  transition: all linear 0.3s;
}
.case-studies-block.style-two .row .item-main {
  position: relative;
  cursor: pointer;
}
.case-studies-block.style-two .row .item-main .bg-img {
  position: relative;
}
.case-studies-block.style-two .row .item-main .bg-img img {
  transition: all linear 0.5s;
  cursor: pointer;
}
.case-studies-block.style-two .row .item-main .bg-img::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #121212;
  opacity: 0;
  transition: all ease 0.3s;
  z-index: 0;
}
.case-studies-block.style-two .row .item-main .bg-img a {
  position: absolute;
  bottom: 20px;
  right: -120px;
  z-index: 1;
  transition: all ease 0.5s;
}
.case-studies-block.style-two .row .item-main .bg-img a:hover {
  background-color: var(--blue);
}
.case-studies-block.style-two .row .item-main .bg-img a:hover div,
.case-studies-block.style-two .row .item-main .bg-img a:hover i {
  color: var(--white);
}
.case-studies-block.style-two .row .item-main .infor .heading6 {
  transition: 0.3s;
}
.case-studies-block.style-two .row .item-main:hover .bg-img img {
  transform: scale(1.06);
}
.case-studies-block.style-two .row .item-main:hover .bg-img::after {
  opacity: 0.4;
}
.case-studies-block.style-two .row .item-main:hover .bg-img a {
  right: 20px;
}
.case-studies-block.style-two .row .item-main:hover .infor .heading6 {
  text-decoration: underline;
}

@media (max-width: 1023.99px) {
  .case-studies-block.style-one .row .item-main .bg-img a,
  .case-studies-block.style-two .row .item-main .bg-img a {
    right: 20px;
    padding: 21px 10px;
  }
}
/*
*** Case Studies Page - Style 2: End
*/
/*
*** Section Case Studies - Home3: Start
*/
.case-studies-block.style-two.style-three {
  width: 100%;
  overflow: hidden;
}
.case-studies-block.style-two.style-three .container .row {
  flex-wrap: nowrap;
}
.case-studies-block.style-two.style-three .container .row .slick-list {
  overflow: unset;
}
.case-studies-block.style-two.style-three .container .row .item-main .bg-img a:hover {
  background-color: var(--gradient);
}
@media (max-width: 1169.99px) {
  .case-studies-block.style-two.style-three .container .row .item-filter {
    width: 45%;
  }
}
@media (max-width: 991.99px) {
  .case-studies-block.style-two.style-three .container .heading {
    flex-direction: column;
    row-gap: 32px;
  }
}
@media (max-width: 767.99px) {
  .case-studies-block.style-two.style-three .container .heading {
    row-gap: 20px;
    padding-bottom: 24px;
  }
}
.case-studies-block.style-two.style-three .list-nav .nav-item.active,
.case-studies-block.style-two.style-three .list-nav .nav-item:hover {
  background-color: var(--gradient);
}

/*
*** Section Case Studies - Home3: End
*/
/*
Case Studies Detail: Start
*/
/*
Project Information: Start
*/
@media (max-width: 575.99px) {
  .project-infor .container .row > div:first-child .count-number .item .body3 {
    font-size: 12px;
  }
}

/*
Project Information: End
*/
/*
How we did: Start
*/
.how-we-did .container .row > div:first-child .bg-img {
  position: relative;
}
.how-we-did .container .row > div:first-child .bg-img i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: var(--critical);
  transition: all ease 0.4s;
  padding: 26px;
}
.how-we-did .container .row > div:first-child .bg-img i:hover {
  background-color: rgb(252, 63, 63);
  color: var(--white);
}
@media (max-width: 480.99px) {
  .how-we-did .container .row > div:first-child .bg-img i {
    font-size: 20px;
    padding: 15px;
  }
}
@media (max-width: 991.99px) {
  .how-we-did .desc {
    margin-left: 0;
  }
  .how-we-did .desc .mt-40 {
    margin-top: 16px;
  }
}

/*
How we did: End
*/
/*
Layout Final outcome of this project(Shared layout Registering and Using Online Banking Services - home3): Start
*/
.layout-item .col-12:first-child .button-block a {
  position: relative;
}
.layout-item .col-12:first-child .button-block a span {
  padding-left: 28px;
}
.layout-item .col-12:first-child .button-block a i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 991.99px) {
  .layout-item .col-12 .bg-img {
    padding-left: 0;
  }
}

/*
Layout Final outcome of this project(Shared layout Registering and Using Online Banking Services - home3): End
*/
/*
Case Studies Detail: End
*/
/*
Section Testimonial Home2: Start
*/
.testimonials-block.style-two .list-comment .comment-item .content {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  text-transform: capitalize;
}
@media (max-width: 767.99px) {
  .testimonials-block.style-two .list-comment {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .testimonials-block.style-two .list-comment .comment-item .heading3,
  .testimonials-block.style-two .list-comment .comment-item .content {
    flex: 0 0 85%;
    max-width: 85%;
  }
  .testimonials-block.style-two .list-comment .comment-item .content {
    font-size: 20px;
    line-height: 32px;
  }
}
.testimonials-block.style-two .slick-initialized .slick-slide {
  display: flex;
}
.testimonials-block.style-two .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 16px;
}
.testimonials-block.style-two .slick-dots li {
  position: relative;
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.testimonials-block.style-two .slick-dots li button {
  display: none;
}
.testimonials-block.style-two .slick-dots li::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--secondary);
  transition: 0.5s;
}
.testimonials-block.style-two .slick-dots li.slick-active {
  border: 1px solid var(--on-surface);
  background-color: #fff;
}
.testimonials-block.style-two .slick-dots li.slick-active::after {
  background-color: var(--on-surface);
  border: 1px solid var(--on-surface);
}

/*
Section Testimonial Home2: End
*/
/*
Section Testimonial Home3, Home4: Start
*/
.testimonials-three .slick-list,
.testimonials-four .slick-list {
  padding: 40px 0;
}
@media (max-width: 767.99px) {
  .testimonials-three .slick-list,
  .testimonials-four .slick-list {
    padding: 24px 0;
  }
}
.testimonials-three .slick-dots,
.testimonials-four .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 16px;
}
@media (max-width: 767.99px) {
  .testimonials-three .slick-dots,
  .testimonials-four .slick-dots {
    margin-top: 10px;
  }
}
.testimonials-three .slick-dots li,
.testimonials-four .slick-dots li {
  position: relative;
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.testimonials-three .slick-dots li button,
.testimonials-four .slick-dots li button {
  display: none;
}
.testimonials-three .slick-dots li::after,
.testimonials-four .slick-dots li::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--secondary);
  transition: 0.5s;
}
.testimonials-three .slick-dots li.slick-active,
.testimonials-four .slick-dots li.slick-active {
  border: 1px solid var(--on-surface);
  background-color: #fff;
}
.testimonials-three .slick-dots li.slick-active::after,
.testimonials-four .slick-dots li.slick-active::after {
  background-color: var(--on-surface);
  border: 1px solid var(--on-surface);
}

.testimonials-four .slick-list {
  padding: 40px 0;
}
@media (max-width: 767.99px) {
  .testimonials-four .slick-list {
    padding: 24px 0;
  }
}
.testimonials-four .slick-dots {
  margin-top: 0;
}

/*
Section Testimonial Home3, Home4: End
*/
/*
Section Testimonial Home6: Start
*/
.testimonials-six .container > .row {
  margin-top: 90px;
}
.testimonials-six .container > .row > div .row {
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(30px);
  overflow: hidden;
}
.testimonials-six .container > .row > div .row > div:first-child .comment-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
}
.testimonials-six .container > .row > div .row > div:first-child .comment-item i.icon-quotes {
  opacity: 0.2;
  color: var(--white);
}
.testimonials-six .container > .row > div .row > div:last-child {
  padding-left: 0;
  padding-right: 0;
}
.testimonials-six .container > .row .slick-list {
  overflow: unset;
}
.testimonials-six .container > .row .slick-list .slick-slide {
  padding: 0 20px;
}
.testimonials-six .container > .row .slick-list .slick-slide .row {
  transform: scale(0.8);
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-list .slick-center .row {
  transform: scale(1);
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-list .slick-center .row > div:first-child .comment-item i.icon-quotes {
  font-size: 70px;
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-list .slick-center .row > div:first-child .comment-item .star {
  margin-top: 40px;
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-list .slick-center .row > div:first-child .comment-item .star i {
  font-size: 30px;
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-list .slick-center .row > div:first-child .comment-item .heading7 {
  margin-top: 20px;
  font-size: 24px;
  line-height: 30px;
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-list .slick-center .row > div:first-child .comment-item .infor {
  margin-top: 24px;
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-list .slick-center .row > div:first-child .comment-item .infor .text-button-small {
  font-size: 16px;
  line-height: 26px;
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-list .slick-center .row > div:first-child .comment-item .infor .caption2 {
  font-weight: 500;
  transition: all ease 0.7s;
}
.testimonials-six .container > .row .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  gap: 16px;
}
.testimonials-six .container > .row .slick-dots li {
  position: relative;
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.testimonials-six .container > .row .slick-dots li button {
  display: none;
}
.testimonials-six .container > .row .slick-dots li::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--white);
  transition: 0.5s;
}
.testimonials-six .container > .row .slick-dots li.slick-active {
  border: 1px solid var(--white);
  background-color: var(--on-surface);
}
.testimonials-six .container > .row .slick-dots li.slick-active::after {
  background-color: var(--white);
  border: 1px solid var(--on-surface);
}
@media (max-width: 1600.99px) {
  .testimonials-six .container > .row .slick-list {
    overflow: unset;
  }
  .testimonials-six .container > .row .slick-list .slick-slide {
    padding: 0 20px;
  }
  .testimonials-six .container > .row .slick-list .slick-center .row {
    transform: scale(1);
  }
}
@media (max-width: 1023.99px) {
  .testimonials-six .container > .row {
    margin-top: 40px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide {
    padding: 0 60px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row {
    transform: scale(1);
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item {
    padding: 24px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item i.icon-quotes {
    font-size: 32px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .star {
    margin-top: 16px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .star i {
    font-size: 14px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .heading7 {
    margin-top: 16px;
    font-size: 18px;
    line-height: 26px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .infor {
    margin-top: 16px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .infor .text-button-small {
    font-size: 14px;
    line-height: 24px;
  }
  .testimonials-six .container > .row .slick-dots {
    margin-top: 40px;
  }
}
@media (max-width: 767.99px) {
  .testimonials-six .container > .row .slick-list .slick-slide {
    padding: 0 32px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row {
    flex-direction: column-reverse;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item {
    padding: 24px 12px;
  }
}
@media (max-width: 767.99px) {
  .testimonials-six .container > .row {
    margin-top: 24px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide {
    padding: 0 30px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item i.icon-quotes {
    font-size: 24px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .star {
    margin-top: 12px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .star i {
    font-size: 12px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .heading7 {
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .infor {
    margin-top: 12px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .infor .text-button-small {
    font-size: 12px;
    line-height: 16px;
  }
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item .infor .caption2 {
    font-size: 11px;
    line-height: 16px;
  }
}
@media (max-width: 575.99px) {
  .testimonials-six .container > .row .slick-list .slick-slide .row > div:first-child .comment-item {
    padding: 16px 8px 20px;
  }
}

/*
Section Testimonial Home6: End
*/
/*
***Blog List: Start
*/
.blog-list .container > .row > div .blog-item {
  transition: all ease 0.5s;
  cursor: pointer;
}
.blog-list .container > .row > div .blog-item .bg-img img {
  transition: all ease 0.5s;
  object-fit: cover;
}
.blog-list .container > .row > div .blog-item .heading6,
.blog-list .container > .row > div .blog-item .read {
  transition: all ease 0.5s;
}
.blog-list .container > .row > div .blog-item:hover .bg-img img {
  transform: scale(1.08);
  transition: all ease 0.5s;
}
.blog-list .container > .row > div .blog-item:hover .heading6 {
  color: var(--blue);
  transition: all ease 0.5s;
}
.blog-list .container > .row > div .blog-item:hover .read {
  text-decoration: underline;
}
@media (max-width: 767.99px) {
  .blog-list .container > .row > div .blog-item .body3 {
    padding-bottom: 6px;
  }
}
.blog-list .container > .row > div > .list-nav .nav-item {
  cursor: pointer;
  transition: all ease 0.3s;
}
.blog-list .container > .row > div > .list-nav .nav-item.active, .blog-list .container > .row > div > .list-nav .nav-item:hover {
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white);
}
.blog-list .container > .row > .list-nav .nav-item {
  cursor: pointer;
  transition: all ease 0.3s;
}
.blog-list .container > .row > .list-nav .nav-item.active, .blog-list .container > .row > .list-nav .nav-item:hover {
  background-color: var(--blue);
  border: 1px solid var(--blue);
  color: var(--white);
}
.blog-list .container > .row .search-block {
  position: relative;
  height: 50px;
}
.blog-list .container > .row .search-block input {
  height: 100%;
  padding-left: 16px;
  padding-right: 52px;
  color: var(--secondary);
  border: 1px solid transparent;
  outline: none;
}
.blog-list .container > .row .search-block input:focus {
  border: 1px solid var(--blue);
}
.blog-list .container > .row .search-block i {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 8px 8px 0;
  font-size: 20px;
  color: var(--secondary);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 0.3s;
}
.blog-list .container > .row .search-block i:hover {
  background-color: var(--blue);
  color: var(--white);
}
.blog-list .container > .row .cate-block .list-nav .nav-item.active, .blog-list .container > .row .cate-block .list-nav .nav-item:hover {
  background-color: var(--surface);
  color: var(--on-surface);
}
.blog-list .container > .row .cate-block .list-nav .nav-item.active i, .blog-list .container > .row .cate-block .list-nav .nav-item:hover i {
  visibility: visible;
}
.blog-list .container > .row .recent-post-block .recent-post-item .item-img img {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  object-fit: cover;
}
.blog-list .container > .row .recent-post-block .recent-post-item .item-title {
  transition: all ease 0.5s;
}
.blog-list .container > .row .recent-post-block .recent-post-item:hover .item-title {
  color: var(--blue);
}
@media (max-width: 991.99px) {
  .blog-list .container > .row .pl-55 {
    padding-left: 15px;
  }
}
.blog-list.style-masonry .container > .row {
  display: block;
  columns: 3;
  column-gap: 32px;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  padding-bottom: 80px;
}
.blog-list.style-masonry .container > .row .blog-item {
  break-inside: avoid;
  width: 100%;
  margin-bottom: 30px;
}
.blog-list.style-masonry .container > .row .blog-item:first-child .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(9) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(10) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(18) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(19) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(27) .blog-item-main .bg-img img {
  width: 125%;
  object-fit: cover;
}
.blog-list.style-masonry .container > .row .blog-item:nth-child(4) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(6) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(8) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(13) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(15) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(17) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(22) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(24) .blog-item-main .bg-img img, .blog-list.style-masonry .container > .row .blog-item:nth-child(26) .blog-item-main .bg-img img {
  width: 150%;
  object-fit: cover;
}
.blog-list.style-masonry .container > .row .list-nav {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1023.99px) {
  .blog-list.style-masonry .container > .row {
    columns: 2;
  }
  .blog-list.style-masonry .container > .row .blog-item:nth-child(4) .blog-item-main .bg-img img {
    width: 100%;
  }
}
@media (max-width: 575.99px) {
  .blog-list.style-masonry .container > .row {
    columns: 1;
    padding-bottom: 50px;
  }
}

/*
Lastest News - Home 3: Change color - Start
*/
.style-three .blog-list .container > .row > div .blog-item-main:hover .heading6 {
  color: var(--gradient) !important;
}

/*
Lastest News - Home 3: Change color - End
*/
/*
Lastest Blogs - Home 6: Change color - Start
*/
.blog-list.style-six .container > .row .blog-item .blog-item-main {
  border: none;
}
.blog-list.style-six .container > .row .blog-item:hover .heading6 {
  color: var(--orange);
}

/*
Lastest Blogs - Home 6: Change color - End
*/
/*
***Blog Item: Start
*/
.blog-item .blog-item-main {
  cursor: pointer;
  border-radius: 16px;
  display: block;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid var(--line);
  box-shadow: 0px 10px 25px 0px rgba(54, 95, 104, 0.15);
}
.blog-item .blog-item-main .bg-img img {
  transition: all ease 0.5s;
  object-fit: cover;
}
.blog-item .blog-item-main .heading6 {
  transition: all ease 0.5s;
}
.blog-item .blog-item-main:hover .bg-img img {
  transform: scale(1.05);
  transition: all ease 0.5s;
}
.blog-item .blog-item-main:hover .heading6 {
  color: var(--blue);
  transition: all ease 0.5s;
}

@media (max-width: 1169.99px) and (min-width: 576px) {
  .col-lg-show {
    display: block;
  }
}

/*
*** Blog Item: End
*/
/* 
*** Blog Detail - Style 1: Start 
*/
.blog-list.style-detail li {
  list-style: disc;
}
.blog-list.style-detail .blog-paragraph .paragraph-content .review {
  border-left: 1px solid var(--blue);
}
.blog-list.style-detail .blog-paragraph .paragraph-content .review .body2 {
  font-style: italic;
}
.blog-list.style-detail .blog-paragraph .paragraph-content .review ul {
  padding-left: 20px;
}
.blog-list.style-detail .blog-paragraph .paragraph-content .review ul li {
  text-transform: uppercase;
}
.blog-list.style-detail .blog-paragraph .paragraph-content .list-li {
  padding-left: 12px;
  padding-right: 12px;
}
.blog-list.style-detail .blog-paragraph .paragraph-content .list-li .body3 i {
  font-size: 18px;
  margin-top: 6px;
}
.blog-list.style-detail .blog-more-infor .infor-above .social-media i {
  background-color: var(--surface);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}
.blog-list.style-detail .blog-more-infor .infor-above .social-media i::before {
  transition: all ease 0.3s;
  color: var(--on-surface);
}
.blog-list.style-detail .blog-more-infor .infor-above .social-media i:hover {
  background-color: var(--blue);
}
.blog-list.style-detail .blog-more-infor .infor-above .social-media i:hover::before {
  color: var(--white);
}
.blog-list.style-detail .blog-more-infor .infor-above .social-media i.icon-twitter {
  margin-left: 1px;
  margin-top: 2px;
}
.blog-list.style-detail .blog-more-infor .infor-below .prev-block .heading7,
.blog-list.style-detail .blog-more-infor .infor-below .next-block .heading7 {
  transition: all ease 0.3s;
}
.blog-list.style-detail .blog-more-infor .infor-below .prev-block:hover .heading7,
.blog-list.style-detail .blog-more-infor .infor-below .next-block:hover .heading7 {
  color: var(--blue);
}
.blog-list.style-detail .blog-form-contact input,
.blog-list.style-detail .blog-form-contact textarea {
  border: 1px solid transparent;
  outline: none;
}
.blog-list.style-detail .blog-form-contact input:focus,
.blog-list.style-detail .blog-form-contact textarea:focus {
  border: 1px solid var(--blue);
}
@media (max-width: 1169.99px) {
  .blog-list.style-detail .infor-above {
    row-gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .blog-list.style-detail .infor-below {
    flex-direction: column;
    align-items: flex-start;
  }
  .blog-list.style-detail .infor-below .next-block {
    margin-top: 20px;
  }
  .blog-list.style-detail .infor-below .next-block a {
    text-align: left;
  }
}
@media (max-width: 991.99px) {
  .blog-list.style-detail .container > .row {
    flex-direction: column-reverse;
  }
  .blog-list.style-detail .container > .row .pr-40 {
    padding-right: 15px;
  }
}
@media (max-width: 575.99px) {
  .blog-list.style-detail .infor-above .share-block {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* 
*** Blog Detail - Style 1: End 
*/
/*
*** Blog Detail - Style 2: Start
*/
.blog-detail-two .slider-sub .container .text-nav {
  width: 100%;
}
.blog-detail-two .social-media a i::before {
  margin-left: 2px;
}
@media (max-width: 360.99px) {
  .blog-detail-two .slider-sub {
    height: 320px;
  }
}

/*
*** Blog Detail - Style 2: End
*/
/*
Section Payment Gateway Services (first) Home1: Start
*/
.payment-gateway-one {
  position: relative;
}
.payment-gateway-one > .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  bottom: 0;
}
.payment-gateway-one > .bg-img img {
  object-fit: cover;
}
.payment-gateway-one .row {
  justify-content: right;
}
.payment-gateway-one .row .col-12.col-xl-6 .payment-infor {
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 80px;
  gap: 32px;
}
.payment-gateway-one .row .col-12.col-xl-6 .payment-infor .heading .bg-img {
  width: 120px;
}
.payment-gateway-one .button-block {
  position: relative;
}
.payment-gateway-one .button-block .button-share:nth-child(2) {
  z-index: 1;
}
.payment-gateway-one .button-block > img {
  position: absolute;
  left: 376px;
  z-index: 0;
}
@media (max-width: 1169.99px) {
  .payment-gateway-one > .bg-img {
    width: 100%;
    position: relative;
  }
  .payment-gateway-one .row .col-12.col-xl-6 .payment-infor {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 0px;
  }
}
@media (max-width: 767.99px) {
  .payment-gateway-one .row .col-12.col-xl-6 .payment-infor {
    padding-top: 20px;
    padding-bottom: 32px;
    gap: 16px;
  }
  .payment-gateway-one .button-block .button-share.pt-14.pb-14 {
    padding: 11px 24px;
  }
  .payment-gateway-one .button-block img {
    left: 300px;
  }
}
@media (max-width: 399.99px) {
  .payment-gateway-one .button-block {
    flex-direction: column;
    align-items: flex-start;
  }
  .payment-gateway-one .button-block img {
    left: 144px;
    top: 36px;
  }
}

@keyframes moveY {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes moveX {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
/*
Section Payment Gateway Services (first) Home1: End
*/
/*
Section Payment Gateway Services (second) Home1: Start
*/
.payment-gateway-two .container .row.row-gap-32 > div:last-child .right {
  position: relative;
}
.payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item {
  position: absolute;
}
.payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(2) {
  left: 40px;
  top: 18%;
  animation: moveY 8s ease infinite;
}
.payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(2) i {
  padding: 16px 12px;
}
.payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(3) {
  right: 0px;
  top: 30%;
  animation: moveY 8s ease infinite;
}
.payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(4) {
  right: 40px;
  bottom: 0px;
  animation: moveX 8s ease infinite;
}
@media (max-width: 767.99px) {
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right {
    padding-left: 0;
  }
}
@media (max-width: 620.99px) {
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right {
    padding-left: 0;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(2) {
    padding: 8px 12px;
    border-radius: 16px;
    gap: 8px;
    left: 10px;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(2) i {
    font-size: 14px;
    padding: 10px 8px;
    border-radius: 12px;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(2) .text .heading7 {
    font-size: 12px;
    line-height: 16px;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(3) {
    padding: 8px 12px;
    border-radius: 16px;
    gap: 8px;
    right: -10px;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(3) i {
    font-size: 20px;
    padding: 0;
    border-radius: 12px;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(3) .text .heading7 {
    font-size: 12px;
    line-height: 16px;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(4) {
    padding: 8px 12px;
    border-radius: 16px;
    gap: 8px;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(4) i {
    font-size: 20px;
    padding: 8px 9px;
    border-radius: 12px;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(4) .text .heading7 {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 1169.99px) {
  .payment-gateway-two .container .row.row-gap-32 {
    flex-direction: column-reverse;
    align-items: center;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child {
    width: 70%;
  }
}
@media (max-width: 991.99px) {
  .payment-gateway-two .container .row.row-gap-32 > div:last-child {
    width: 90%;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(3) {
    right: -20px;
  }
}
@media (max-width: 575.99px) {
  .payment-gateway-two .container .row.row-gap-32 > div:last-child {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .payment-gateway-two .container .row.row-gap-32 > div:last-child .right .feature-item:nth-child(3) {
    right: -10px;
  }
}

/*
Section Payment Gateway Services (second) Home1: End
*/
/*
Section Benefit(Case Studies) Home1: Start
*/
.benefit-block.style-one .list-benefit .row {
  margin-left: 0;
  margin-right: 0;
}
.benefit-block.style-one .list-benefit .row > div {
  padding-right: 0;
  padding-left: 0;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item {
  position: relative;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item .text {
  position: absolute;
  bottom: 60px;
  left: 60px;
  right: 60px;
  transition: all ease 0.5s;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item .text .heading5 {
  position: relative;
  display: inline-block;
  margin-left: 0;
  transition: all ease 0.3s;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item .text .heading5 a {
  position: relative;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item .text .heading5 a::before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transition: all ease 0.4s;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item .text .heading5:hover a::before {
  width: 100%;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item .text a:last-child i {
  margin-left: 0;
  transition: all ease 0.3s;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item .text a:last-child:hover i {
  margin-left: 6px;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background-color: var(--blue);
  opacity: 0.6;
  transition: all ease 0.5s;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item:hover::before {
  height: 100%;
}
.benefit-block.style-one .list-benefit .row > div .benefit-item:hover .text {
  bottom: 140px;
}
@media (max-width: 575.99px) {
  .benefit-block.style-one i.ph-caret-double-right {
    margin-top: 0 !important;
  }
}

/*
Section Benefit(Case Studies) Home1: End
*/
/*
Section FAQs (Frequently Asked questions) Home1: Start
*/
.faqs-block.style-one {
  position: relative;
}
.faqs-block.style-one > .row {
  width: 100%;
  height: 720px;
  overflow: hidden;
}
.faqs-block.style-one > .row > div:first-child {
  height: 100%;
  padding-right: 0;
}
.faqs-block.style-one > .row > div:first-child .bg-img {
  height: 66.6666666667%;
}
.faqs-block.style-one > .row > div:first-child .bg-img img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: cover;
}
.faqs-block.style-one > .row > div:first-child .desc {
  height: 33.3333333333%;
}
.faqs-block.style-one > .row > div:first-child .desc .content {
  width: 70%;
  display: flex;
  align-items: center;
}
.faqs-block.style-one > .row > div:first-child .desc .content i {
  font-size: 100px;
}
.faqs-block.style-one > .row > div:last-child {
  overflow: hidden;
}
.faqs-block.style-one > .row > div:last-child .content-main {
  width: 70%;
  padding-left: 60px;
  padding-top: 40px;
}
.faqs-block.style-one > .row > div:last-child .content-main .list-question .question-item .heading7 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}
.faqs-block.style-one > .row > div:last-child .content-main .list-question .question-item i {
  font-size: 16px;
}
@media (min-width: 2000.99px) {
  .faqs-block.style-one > .row > div:last-child .content-main {
    width: 60%;
  }
}
@media (min-width: 2480.99px) {
  .faqs-block.style-one > .row > div:last-child .content-main {
    width: 50%;
  }
}
@media (min-width: 2980.99px) {
  .faqs-block.style-one > .row > div:last-child .content-main {
    width: 40%;
  }
}
@media (max-width: 1600.99px) {
  .faqs-block.style-one > .row > div:first-child .desc .content {
    width: 80%;
  }
  .faqs-block.style-one > .row > div:last-child .content-main {
    width: 90%;
  }
}
@media (max-width: 1400.99px) {
  .faqs-block.style-one > .row > div:first-child .desc .content i {
    font-size: 80px;
  }
  .faqs-block.style-one > .row > div:first-child .desc .content .heading4 {
    font-size: 32px;
  }
  .faqs-block.style-one > .row > div:last-child .content-main {
    width: 94%;
  }
}
@media (max-width: 1169.99px) {
  .faqs-block.style-one > .row {
    margin-left: 0;
    margin-right: 0;
  }
  .faqs-block.style-one > .row > div:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  .faqs-block.style-one > .row > div:last-child .content-main {
    padding-left: 0;
    width: 100%;
  }
}
@media (max-width: 767.99px) {
  .faqs-block.style-one > .row > div:first-child .desc .content {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .faqs-block.style-one > .row > div:first-child .desc .content i {
    font-size: 60px;
  }
}
@media (max-width: 1169.99px) {
  .faqs-block.style-one > .row {
    height: unset;
    row-gap: 0;
  }
  .faqs-block.style-one > .row > div:first-child .bg-img img {
    max-height: unset;
    height: unset;
  }
  .faqs-block.style-one > .row > div:first-child .desc {
    padding-left: 15px;
    padding-right: 15px;
  }
  .faqs-block.style-one > .row > div:first-child .desc .content {
    padding: 30px 0;
  }
  .faqs-block.style-one > .row > div:last-child {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (max-width: 767.99px) {
  .faqs-block.style-one > .row {
    height: unset;
    row-gap: 0;
  }
  .faqs-block.style-one > .row > div:first-child .desc .content i {
    font-size: 50px;
  }
  .faqs-block.style-one > .row > div:first-child .desc .content .heading4 {
    font-size: 20px;
  }
}

/*
Section FAQs (Frequently Asked questions) Home1: End
*/
/*
Section Form request (Request a free call back) Home1: Start
*/
.form-request .form .row {
  row-gap: 16px;
}
.form-request .form .row > div:last-child {
  position: relative;
}
.form-request .form .row > div:last-child i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
.form-request .form .button-block button {
  outline: none;
  border: none;
}
@media (max-width: 1300.99px) {
  .form-request .heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}
@media (max-width: 1023.99px) {
  .form-request .form {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 991.99px) {
  .form-request .form .row {
    row-gap: 16px;
    margin-left: 0;
    margin-right: 0;
  }
  .form-request .form .row > div {
    padding-left: 0;
  }
  .form-request .form .row > div:nth-child(2),
  .form-request .form .row > div:nth-child(3) {
    padding-right: 0;
  }
}
@media (max-width: 767.99px) {
  .form-request .form .row > div:first-child {
    padding-right: 0;
  }
}
@media (max-width: 680.99px) {
  .form-request .heading .body3 br {
    display: none;
  }
}

/*
Section Form request (Request a free call back) Home1: End
*/
/*
Section About us Home2(Company Overview): Start
*/
.financial-assessment-block .nav-infor .list-nav {
  display: inline-flex;
  border-bottom: 2px solid #E5E5E5;
}
.financial-assessment-block .nav-infor .list-nav .nav-item {
  position: relative;
  cursor: pointer;
}
.financial-assessment-block .nav-infor .list-nav .nav-item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: var(--blue);
  transition: all ease 0.3s;
}
.financial-assessment-block .nav-infor .list-nav .nav-item:hover::after {
  width: 100%;
}
.financial-assessment-block .nav-infor .list-nav .nav-item.active::after {
  width: 100%;
}
.financial-assessment-block .nav-infor .description.hide {
  display: none;
}
.financial-assessment-block .nav-infor .description.show {
  display: block;
  animation: show ease 0.3s !important;
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.financial-assessment-block .button-block .button-share:last-child:hover {
  border: 2px solid var(--on-surface);
}
@media (max-width: 1169.99px) {
  .financial-assessment-block .row {
    row-gap: 32px;
    align-items: flex-start;
    flex-direction: column;
  }
  .financial-assessment-block .row .flex-column {
    gap: 24px;
  }
  .financial-assessment-block .row .flex-column .mt-40 {
    margin-top: 0px;
  }
  .financial-assessment-block .row .flex-column .ml-40 {
    margin-left: 0;
  }
}
@media (max-width: 575.99px) {
  .financial-assessment-block .row {
    row-gap: 16px;
  }
  .financial-assessment-block .row .flex-column {
    gap: 12px;
  }
}
@media (max-width: 459.99px) {
  .financial-assessment-block .button-block {
    flex-direction: column;
    align-items: flex-start;
  }
}

/*
Section About us Home2(Company Overview): End
*/
/*
Section counter (count number) Home2: Start
*/
.style-two .count-number-block .container {
  border-top: 1px solid var(--line);
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 575.99px) {
  .style-two .count-number-block {
    margin-top: 32px;
  }
  .style-two .count-number-block .container {
    padding-top: 24px;
    padding-bottom: 12px;
  }
}

@media (max-width: 767.99px) {
  .count-number-block .count-block .counter, .count-number-block .count-block .heading3 {
    font-size: 36px;
    line-height: 44px;
  }
}
/*
Section counter (count number) Home2: End
*/
/*
Section Our Project Home2: Start
*/
.our-project-block .list-project .row {
  margin-left: -5px;
  margin-right: -5px;
}
.our-project-block .list-project .row .col-12 {
  padding-left: 5px;
  padding-right: 5px;
}
.our-project-block .list-project .row .col-12 .item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all ease 0.3s;
}
.our-project-block .list-project .row .col-12 .item .bg-img {
  position: relative;
}
.our-project-block .list-project .row .col-12 .item .bg-img img {
  transition: all ease 0.4s;
}
.our-project-block .list-project .row .col-12 .item .bg-img::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: var(--blue);
  opacity: 0.6;
  z-index: 1;
  transition: all ease 0.4s;
}
.our-project-block .list-project .row .col-12 .item .text {
  position: absolute;
  left: 28px;
  bottom: 28px;
  z-index: 2;
}
.our-project-block .list-project .row .col-12 .item .text .heading5 {
  position: relative;
}
.our-project-block .list-project .row .col-12 .item .text .heading5::before {
  position: absolute;
  content: "";
  background-color: #fff;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  transition: all ease 0.4s;
}
.our-project-block .list-project .row .col-12 .item .text .heading5:hover::before {
  width: 100%;
}
.our-project-block .list-project .row .col-12 .item .arrow {
  position: absolute;
  right: -60px;
  bottom: 38px;
  transition: all ease 0.3s;
  z-index: 2;
}
.our-project-block .list-project .row .col-12 .item .arrow:hover {
  background-color: var(--blue);
}
.our-project-block .list-project .row .col-12 .item .arrow:hover i {
  color: var(--white);
}
.our-project-block .list-project .row .col-12 .item .arrow i {
  display: block;
}
.our-project-block .list-project .row .col-12 .item:hover .bg-img img {
  transform: scale(1.05);
}
.our-project-block .list-project .row .col-12 .item:hover .bg-img::before {
  height: 100%;
}
.our-project-block .list-project .row .col-12 .item:hover .arrow {
  right: 28px;
}
.our-project-block .list-project .row .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
}
@media (max-width: 575.99px) {
  .our-project-block .list-project .row .slick-dots {
    margin-top: 24px;
  }
}
.our-project-block .list-project .row .slick-dots li {
  position: relative;
  border: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.our-project-block .list-project .row .slick-dots li button {
  display: none;
}
.our-project-block .list-project .row .slick-dots li::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--secondary);
}
.our-project-block .list-project .row .slick-dots li.slick-active {
  border: 1px solid var(--on-surface);
  background-color: #fff;
}
.our-project-block .list-project .row .slick-dots li.slick-active::after {
  background-color: var(--on-surface);
  border: 1px solid var(--on-surface);
}
@media (max-width: 1023.99px) {
  .our-project-block .list-project .row .col-12 .item .text {
    bottom: 40px;
  }
  .our-project-block .list-project .row .col-12 .item .arrow {
    bottom: 20px;
    right: 28px;
  }
}
@media (max-width: 767.99px) {
  .our-project-block .list-project .row .col-12 .item .text {
    left: 20px;
    bottom: 20px;
  }
  .our-project-block .list-project .row .col-12 .item .text .body3 {
    margin-top: 0 !important;
  }
  .our-project-block .list-project .row .col-12 .item .arrow {
    right: 18px;
    bottom: 20px;
  }
  .our-project-block .list-project .row .col-12 .item .arrow i {
    font-size: 18px;
    padding: 10px;
  }
  .our-project-block .list-project .row .col-12 .item:hover .arrow {
    right: 18px;
  }
}

/*
Section Our Project Home2: End
*/
/*
Section Benefit (Form Request) Home2: Start
*/
.form-cta-block.benefit-two .bg-img img {
  position: absolute;
}
.form-cta-block.benefit-two .container > .row > div:last-child .form-block button {
  border: none;
}
.form-cta-block.benefit-two .container > .row > div:last-child .form-block button:hover {
  background-color: var(--on-surface);
  color: var(--white);
}
@media (max-width: 991.99px) {
  .form-cta-block.benefit-two {
    height: 720px;
  }
}
@media (max-width: 767.99px) {
  .form-cta-block.benefit-two {
    height: 560px;
  }
}
@media (max-width: 575.99px) {
  .form-cta-block.benefit-two {
    height: 640px;
  }
}

/*
Section Benefit (Form Request) Home2: End
*/
/*
Section Features Home3: Start
*/
.features-three .container .row {
  position: relative;
  row-gap: 50px;
}
.features-three .container .row > div:first-child .list-nav-item .nav-item {
  border-bottom: 1px solid var(--line);
  cursor: pointer;
}
.features-three .container .row > div:first-child .list-nav-item .nav-item:last-child {
  border-bottom: none;
}
.features-three .container .row > div:first-child .list-nav-item .nav-item.active {
  color: var(--white);
  background-color: var(--gradient);
  border-radius: 4px;
}
.features-three .container .row .content-filter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: right;
}
.features-three .container .row .content-filter .bg-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 265px;
  z-index: 0;
}
.features-three .container .row .content-filter .bg-img img {
  width: 100%;
}
.features-three .container .row .content-filter .infor {
  position: relative;
  margin-right: 102px;
}
@media (max-width: 1169.99px) {
  .features-three.pb-60 {
    padding-bottom: 0;
  }
  .features-three .container .row .content-filter {
    align-items: center;
    justify-content: center;
  }
  .features-three .container .row .content-filter .bg-img {
    width: 80%;
    height: 240px;
    left: 50%;
    transform: translateX(-50%);
    top: unset;
    bottom: 50px;
  }
  .features-three .container .row .content-filter .bg-img img {
    height: 100%;
    object-fit: cover;
  }
  .features-three .container .row .content-filter .infor {
    padding-top: 40px;
    width: 70%;
    margin-right: 0;
    margin-bottom: 100px;
  }
}
@media (max-width: 1169.99px) {
  .features-three .container .row > div:first-child {
    padding-right: 15px;
  }
  .features-three .container .row .content-filter .bg-img {
    width: 100%;
  }
  .features-three .container .row .content-filter .infor {
    width: 90%;
  }
}
@media (max-width: 575.99px) {
  .features-three.pb-60 {
    padding-bottom: 0;
  }
  .features-three .container .row > div:nth-child(3) .bg-img,
  .features-three .container .row > div:nth-child(4) .bg-img,
  .features-three .container .row > div:nth-child(5) .bg-img {
    height: 200px;
  }
  .features-three .container .row > div:nth-child(3) .infor,
  .features-three .container .row > div:nth-child(4) .infor,
  .features-three .container .row > div:nth-child(5) .infor {
    padding-top: 0;
    margin-bottom: 120px;
  }
}
@media (max-width: 419.99px) {
  .features-three .container .row {
    row-gap: 24px;
  }
  .features-three .container .row > div:nth-child(3) .bg-img,
  .features-three .container .row > div:nth-child(4) .bg-img,
  .features-three .container .row > div:nth-child(5) .bg-img {
    height: 180px;
  }
  .features-three .container .row > div:nth-child(3) .infor,
  .features-three .container .row > div:nth-child(4) .infor,
  .features-three .container .row > div:nth-child(5) .infor {
    padding-top: 0;
    margin-bottom: 120px;
  }
}

@keyframes showItem {
  from {
    transform: scale(0.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.content-filter.hide {
  display: none !important;
}

.content-filter.show {
  display: flex !important;
  animation: showItem ease-in-out 0.5s;
}

/*
Section Features Home3: End
*/
/*
Section Benefit(Form Request) Home3: Start
*/
.form-cta-block.benefit-three {
  height: unset !important;
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 767.99px) {
  .form-cta-block.benefit-three {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.form-cta-block.benefit-three .container > .row > div:last-child .form-block button {
  border: none;
}
.form-cta-block.benefit-three .container > .row > div:last-child .form-block button:hover {
  background-color: var(--blue);
  color: var(--white);
}

/*
Section Benefit(Form Request) Home3: End
*/
/*
Section Counter Home4: Start
*/
.video-block.style-four .video {
  position: relative;
  margin-bottom: -100px;
  border-radius: 24px;
  overflow: hidden;
}
.video-block.style-four .video i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all ease 0.3s;
}
.video-block.style-four .video i:hover {
  background-color: var(--critical);
  color: var(--white);
}

.count-number-block.style-four {
  background-color: var(--deep-purple);
}
.count-number-block.style-four .row {
  padding-bottom: 80px;
  padding-top: 180px;
}

@media (max-width: 767.99px) {
  .video-block.style-four .video {
    margin-bottom: -120px;
  }
  .video-block.style-four .video i {
    padding: 16px;
  }
  .count-number-block.style-four .row {
    padding-bottom: 60px;
    padding-top: 150px;
  }
}
@media (max-width: 399.99px) {
  .video-block.style-four .video {
    margin-bottom: -100px;
  }
  .video-block.style-four .video img {
    height: 170px;
    object-fit: cover;
  }
  .video-block.style-four .video i {
    padding: 12px;
    font-size: 16px;
  }
  .count-number-block.style-four .row {
    padding-bottom: 60px;
    padding-top: 120px;
  }
}
/*
Section Counter Home4: End
*/
/*
Section Our Pricing Home4: Start
*/
.pricing-block.style-four .pricing-main .heading .switch.enable .bg-placehover {
  background-color: var(--success);
}
.pricing-block.style-four .pricing-main .list-pricing .row > div:nth-child(2) .pricing-item {
  border-top: 4px solid var(--success);
}

@keyframes showList {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.list-pricing.hide {
  display: none;
}

.list-pricing.show {
  display: block;
  animation: showList ease-in-out 0.7s;
}

.list-pricing-year.hide {
  display: none;
}

.list-pricing-year.show {
  display: block;
  animation: showList ease-in-out 0.7s;
}

/*
Section Our Pricing Home4: End
*/
/*
Section Our Blogs Home4: Start
*/
.blog-list.style-four .blog-item-main .bg-img {
  border-radius: 8px;
}
.blog-list.style-four .blog-item-main .bg-img img {
  transition: all ease 0.4s;
}
.blog-list.style-four .blog-item-main .infor .heading4 {
  transition: all ease 0.4s;
}
.blog-list.style-four .blog-item-main:hover .bg-img img {
  transform: scale(1.06);
}
.blog-list.style-four .blog-item-main:hover .infor .heading4 {
  color: var(--success);
}
.blog-list.style-four .list-blog-related a .bg-img img {
  transition: all ease 0.4s;
}
.blog-list.style-four .list-blog-related a .infor .heading6 {
  transition: all ease 0.4s;
}
.blog-list.style-four .list-blog-related a:hover .bg-img img {
  transform: scale(1.06);
}
.blog-list.style-four .list-blog-related a:hover .infor .heading6 {
  color: var(--success);
}
@media (max-width: 991.99px) {
  .blog-list.style-four .container > .row .pr-55 {
    padding-right: 15px;
  }
}

/*
Section Our Blogs Home4: End
*/
/*
Section Form Resquest Home4: Start
*/
.form-request-block.style-four {
  position: relative;
  width: 100%;
  margin-bottom: -1px;
}
.form-request-block.style-four .bg-img {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.form-request-block.style-four .bg-img img {
  object-fit: cover;
  display: block;
}
.form-request-block.style-four .form {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 16px;
  border-radius: 16px;
}
.form-request-block.style-four .form .row {
  row-gap: 16px;
}
.form-request-block.style-four .form .row > div:last-child {
  position: relative;
}
.form-request-block.style-four .form .row > div:last-child i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
.form-request-block.style-four .form .button-block button {
  outline: none;
  border: none;
}
@media (max-width: 1023.99px) {
  .form-request-block.style-four .form {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 991.99px) {
  .form-request-block.style-four .form .row {
    row-gap: 16px;
    margin-left: 0;
    margin-right: 0;
  }
  .form-request-block.style-four .form .row > div {
    padding-left: 0;
  }
  .form-request-block.style-four .form .row > div:nth-child(2),
  .form-request-block.style-four .form .row > div:nth-child(3) {
    padding-right: 0;
  }
}
@media (max-width: 767.99px) {
  .form-request-block.style-four .form .row > div:first-child {
    padding-right: 0;
  }
}

/*
Section Form Resquest Home4: End
*/
/*
Section About Home5: Start
*/
.about-home-five .container .row > div:last-child {
  position: relative;
}
.about-home-five .container .row > div:last-child .bg-img {
  position: relative;
  z-index: 1;
}
.about-home-five .container .row > div:last-child .bg-img img {
  z-index: 1;
}
.about-home-five .container .row > div:last-child .sub-img img:first-child {
  position: absolute;
  left: 78px;
  bottom: 54px;
  width: 126px;
  height: 126px;
  animation: moveleft 5s ease infinite;
}
.about-home-five .container .row > div:last-child .sub-img img:last-child {
  position: absolute;
  right: 60px;
  bottom: -20px;
  width: 208px;
  height: 208px;
  z-index: 2;
  animation: moveright 5s ease infinite;
}
@media (max-width: 991.99px) {
  .about-home-five .container .row {
    justify-content: center;
  }
}
@media (max-width: 575.99px) {
  .about-home-five .container .row > div:last-child .sub-img img:first-child {
    width: 80px;
    height: 80px;
  }
  .about-home-five .container .row > div:last-child .sub-img img:last-child {
    width: 120px;
    height: 120px;
  }
}

@keyframes moveleft {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(-10px, 20px);
  }
  100% {
    transform: translate(0px);
  }
}
@keyframes moveright {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(10px, -20px);
  }
  100% {
    transform: translate(0px);
  }
}
/*
Section About Home5: End
*/
/*
Section Card(One of the Most Trusted Wealth Management Companies) Home5: Start
*/
.card-block .bg-img {
  background-color: #D9D9D9;
  height: 450px;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
}
.card-block .bg-img img {
  position: absolute;
  transition: all ease 0.5s;
}
.card-block .bg-img img:first-child {
  bottom: -120px;
  left: 32%;
  transform: translateX(-50%);
}
.card-block .bg-img img:nth-child(2) {
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.card-block .bg-img img:last-child {
  bottom: -120px;
  left: 68%;
  transform: translateX(-50%);
}
.card-block .bg-img img:hover {
  margin-bottom: 10px;
}
@media (max-width: 1023.99px) {
  .card-block .bg-img {
    height: 400px;
  }
  .card-block .bg-img img:first-child {
    width: 300px;
  }
  .card-block .bg-img img:nth-child(2) {
    width: 220px;
  }
  .card-block .bg-img img:last-child {
    width: 300px;
  }
}
@media (max-width: 575.99px) {
  .card-block .bg-img {
    height: 300px;
  }
  .card-block .bg-img img:first-child {
    width: 240px;
  }
  .card-block .bg-img img:nth-child(2) {
    width: 160px;
  }
  .card-block .bg-img img:last-child {
    width: 240px;
  }
}
@media (max-width: 419.99px) {
  .card-block .bg-img {
    height: 200px;
  }
  .card-block .bg-img img:first-child {
    width: 160px;
    bottom: -80px;
  }
  .card-block .bg-img img:nth-child(2) {
    width: 100px;
    bottom: -10px;
  }
  .card-block .bg-img img:last-child {
    width: 160px;
    bottom: -80px;
  }
}

/*
Section Card(One of the Most Trusted Wealth Management Companies) Home5: End
*/
/*
Section Projects Home5: Start
*/
@media (max-width: 991.99px) {
  .project-five .heading .heading3 br {
    display: none;
  }
  .project-five .list-project .project-above .row > div:nth-child(1) {
    padding-right: 15px;
  }
}

/*
Section Projects Home5: End
*/
/*
Section Form Request Home5: Start
*/
.style-five .form-request {
  background-color: var(--surface);
  padding-top: 60px;
  padding-bottom: 60px;
}
.style-five .form-request .container .heading {
  display: block;
}
.style-five .form-request .container .heading .body3 {
  margin-top: 24px;
}
.style-five .form-request .container .form {
  margin-top: 24px;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
}
.style-five .form-request .container .form input,
.style-five .form-request .container .form select {
  background-color: #fff;
  border: 1px solid var(--line);
}
.style-five .form-request .container .form input:focus,
.style-five .form-request .container .form select:focus {
  border: 1px solid var(--blue);
}
@media (max-width: 767.99px) {
  .style-five .form-request {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .style-five .form-request .container .heading .body3 {
    margin-top: 12px;
  }
  .style-five .form-request .container .form {
    margin-top: 12px;
  }
}

/*
Section Form Request Home5: End
*/
/*
Section Video About Home6: Start
*/
.video-about-block .container .bg-img {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: radial-gradient(63.94% 63.94% at 50% 0%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%), rgba(104, 105, 107, 0.2);
  backdrop-filter: blur(6px);
}
.video-about-block .container .bg-img img {
  object-fit: cover;
}
.video-about-block .container .bg-img i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all ease 0.4s;
  cursor: pointer;
}
.video-about-block .container .bg-img i:hover {
  background-color: var(--orange);
  color: var(--white);
}
.video-about-block .container .row .heading6 {
  line-height: 42px;
}
@media (max-width: 767.99px) {
  .video-about-block .container .row .heading6 {
    line-height: 24px;
  }
  .video-about-block .container .bg-img img {
    height: 300px;
  }
}
@media (max-width: 575.99px) {
  .video-about-block .container .bg-img img {
    height: 240px;
  }
  .video-about-block .container .bg-img i {
    font-size: 24px;
    padding: 16px;
  }
}
@media (max-width: 419.99px) {
  .video-about-block .container .bg-img {
    padding: 24px;
  }
  .video-about-block .container .bg-img img {
    height: 200px;
  }
}

/*
Section Video About Home6: End
*/
/*
Section About (People Trust Us) Home6: Start
*/
.people-trust-us .container > .row > div:last-child .row > div:first-child {
  padding-right: 0;
}
@media (max-width: 991.99px) {
  .people-trust-us .container > .row > div:first-child {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .people-trust-us .container > .row > div:first-child .bg-img {
    width: 60%;
  }
}

@keyframes earth {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*
Section About (People Trust Us) Home6: End
*/
/*
Section Pricing Home6: Start
*/
.pricing-six .container .heading .choose-type {
  justify-content: right;
}
.pricing-six .container .heading .choose-type .switch .bg-placehover {
  display: block;
  width: 60px;
  height: 32px;
  border-radius: 99px;
  border: none;
  outline: none;
  position: relative;
  transition: all ease 0.6s;
  overflow: hidden;
  cursor: pointer;
}
.pricing-six .container .heading .choose-type .switch .bg-placehover::before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: all ease 0.3s;
}
.pricing-six .container .heading .choose-type .switch.enable .bg-placehover {
  background-color: var(--orange);
}
.pricing-six .container .heading .choose-type .switch.enable .bg-placehover::before {
  left: 32px;
}
@media (max-width: 991.99px) {
  .pricing-six .container .text-button-uppercase,
  .pricing-six .container .heading {
    text-align: center;
  }
  .pricing-six .container .heading .choose-type {
    justify-content: center;
  }
}
.pricing-six .container > div > .row > div .pricing-item {
  border: 1px solid rgba(255, 255, 255, 0.12);
  transition: all ease 0.5s;
}
.pricing-six .container > div > .row > div .pricing-item .heading3 span {
  text-transform: none;
}
.pricing-six .container > div > .row > div .pricing-item .button-block a {
  border: 2px solid var(--orange);
  transition: all ease 0.4s;
}
.pricing-six .container > div > .row > div .pricing-item .button-block a:hover {
  color: var(--white);
  background-color: var(--orange);
}
@media (max-width: 1260px) {
  .pricing-six .container > div > .row > div .pricing-item .item-infor {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 1260px) {
  .pricing-six .container > div > .row > div .pricing-item .item-infor .text-center {
    text-align: left;
  }
}
.pricing-six .container > div > .row > div:nth-child(2) .pricing-item {
  background: radial-gradient(114.34% 120.2% at 50% -10.41%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(52px);
}
.pricing-six .container > div > .row > div .pricing-item:hover {
  background: radial-gradient(114.34% 120.2% at 50% -10.41%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(52px);
  margin-top: -20px;
  cursor: pointer;
}
@media (max-width: 1023.99px) {
  .pricing-six .container > div > .row > div .pricing-item:hover {
    margin-top: 0;
  }
}

/*
Section Pricing Home6: End
*/
/*
Section How we work Home6: Start
*/
.how-we-work .container > .row {
  margin-top: 80px;
  row-gap: 70px;
}
.how-we-work .container > .row .main-item {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: radial-gradient(41.35% 54.74% at 33.03% -4.74%, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(6px);
  transition: all ease 0.5s;
}
.how-we-work .container > .row .main-item .number {
  position: absolute;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -40px;
}
.how-we-work .container > .row .main-item .heading7 {
  padding-top: 22px;
}
.how-we-work .container > .row .main-item:hover {
  margin-top: -10px;
}
@media (max-width: 1023.99px) {
  .how-we-work .container > .row .main-item:hover {
    margin-top: 0;
  }
}
@media (max-width: 767.99px) {
  .how-we-work .container > .row {
    margin-top: 40px;
    row-gap: 40px;
  }
  .how-we-work .container > .row .main-item .number {
    border-radius: 12px;
    width: 50px;
    height: 50px;
    top: -24px;
  }
  .how-we-work .container > .row .main-item .heading7 {
    padding-top: 12px;
  }
}

/*
Section How we work Home6: End
*/
/*
Contact Us Page: Start
*/
.form-contact .row .list-social .item:nth-child(4) i, .form-contact .row .list-social .item:last-child i {
  margin-left: 2px;
}
@media (max-width: 567.99px) {
  .form-contact .row .list-social .item {
    width: 40px;
    height: 40px;
  }
}
.form-contact .row .list-more-infor .item .line-y {
  height: 28px;
}
.form-contact .row .list-more-infor .item:nth-child(3) .text-button {
  text-transform: none;
}
.form-contact .row .form-block input,
.form-contact .row .form-block select,
.form-contact .row .form-block textarea {
  border: 1px solid transparent;
  outline: none;
}
.form-contact .row .form-block input:focus,
.form-contact .row .form-block select:focus,
.form-contact .row .form-block textarea:focus {
  border: 1px solid var(--blue);
}
.form-contact .row .form-block select {
  -webkit-appearance: none;
}
.form-contact .row .form-block .col-12 {
  position: relative;
}
.form-contact .row .form-block .col-12 input,
.form-contact .row .form-block .col-12 select {
  padding-top: 12px;
  padding-bottom: 12px;
}
.form-contact .row .form-block .col-12 i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
}
.form-contact .row .form-block.style-one button {
  border: 2px solid transparent;
  outline: none;
  transition: 0.3s;
}
.form-contact .row .form-block.style-one button:hover {
  background-color: #fff;
  border: 2px solid var(--blue);
  color: var(--blue);
}
.form-contact.style-two .container > .row > div:last-child {
  border-radius: 16px;
  padding: 40px 44px;
  box-shadow: 0px 1px 25px 0px rgba(54, 95, 104, 0.1);
}
.form-contact.style-two .heading .title {
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: var(--blue);
}
.form-contact.style-two button {
  border: none;
  outline: none;
  transition: 0.3s;
}
.form-contact.style-two button:hover {
  background-color: var(--blue);
}
.form-contact.style-two .map-block iframe {
  width: 100%;
  height: 512px;
  margin-bottom: -6px;
}
@media (max-width: 1169.99px) {
  .form-contact .row .col-12.col-xl-6 {
    padding-left: 15px;
  }
}

/*
Contact Us Page: End
*/
/*
Section Our Team: Start
*/
.our-team-block .container .list-member .col-12 .bg-img {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  cursor: pointer;
}
.our-team-block .container .list-member .col-12 .bg-img img {
  transition: all ease 0.5s;
}
.our-team-block .container .list-member .col-12 .bg-img .list-social {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  right: -80px;
  border-radius: 8px;
  padding: 12px 8px;
  transition: all ease-in-out 0.5s;
}
.our-team-block .container .list-member .col-12 .bg-img .list-social a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.our-team-block .container .list-member .col-12 .bg-img .list-social a i {
  margin-left: 2px;
}
.our-team-block .container .list-member .col-12 .bg-img .list-social a i::before {
  color: var(--on-surface);
}
.our-team-block .container .list-member .col-12 .bg-img .list-social a:hover {
  background-color: var(--blue);
}
.our-team-block .container .list-member .col-12 .bg-img .list-social a:hover i::before {
  color: var(--white);
}
.our-team-block .container .list-member .col-12 .infor .name {
  transition: all ease-in-out 0.3s;
}
.our-team-block .container .list-member .col-12:hover {
  cursor: pointer;
}
.our-team-block .container .list-member .col-12:hover .bg-img img {
  transform: scale(1.1);
}
.our-team-block .container .list-member .col-12:hover .list-social {
  transition: all ease-in-out 0.5s;
  right: 20px;
}
.our-team-block .container .list-member .col-12:hover .infor .name {
  color: var(--blue);
}

/*
Section Our Team: End
*/
/*
Shared List Questions: Start
*/
.question-item .question-item-main i {
  transform: rotate(0);
  transition: all ease 0.5s;
}
@media (max-width: 575.99px) {
  .question-item .question-item-main i {
    font-size: 16px;
  }
}
.question-item .content-question {
  transition: all ease-in-out 0.5s;
  max-height: 0;
  overflow: hidden;
}
.question-item .content-question .border-line {
  background-color: rgba(231, 231, 231, 0.8);
}

.question-item.open .content-question {
  max-height: 150px;
}

/*
Shared List Questions: End
*/
/*
Shared List Pricing main: Start
*/
.pricing-block .pricing-main .heading .switch .bg-placehover {
  display: block;
  width: 80px;
  height: 40px;
  border-radius: 99px;
  border: none;
  outline: none;
  position: relative;
  transition: all ease 0.6s;
  overflow: hidden;
  cursor: pointer;
}
.pricing-block .pricing-main .heading .switch .bg-placehover::before {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  background-color: #fff;
  border-radius: 50%;
  transition: all ease 0.3s;
}
.pricing-block .pricing-main .heading .switch.enable .bg-placehover {
  background-color: var(--blue);
}
.pricing-block .pricing-main .heading .switch.enable .bg-placehover::before {
  left: 44px;
}
.pricing-block .pricing-main .heading .switch.enable .body3:first-child {
  color: var(--placehover);
}
.pricing-block .pricing-main .heading .switch.enable .body3:last-child {
  color: var(--on-surface);
}

/*
Shared List Pricing main: End
*/
/*
List partners page: Start
*/
.list-partner-block img {
  cursor: pointer;
}
.list-partner-block .border-line {
  background-color: rgba(183, 183, 183, 0.2);
}
.list-partner-block .list-partner .item-main {
  justify-content: space-around;
}
.list-partner-block .more-list .row .bg-img {
  height: 165px;
  transition: 0.4s;
}
.list-partner-block .more-list .row .bg-img:hover {
  box-shadow: 1px 4px 20px 0px rgba(37, 82, 91, 0.2);
}
.list-partner-block .slick-initialized .slick-slide {
  display: flex;
}
@media (max-width: 991.99px) {
  .list-partner-block .list-partner .item-main {
    flex-wrap: wrap;
    row-gap: 32px;
  }
  .list-partner-block .list-partner .item-main .bg-img {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 575.99px) {
  .list-partner-block .container .heading3 br {
    display: none;
  }
  .list-partner-block .list-partner .item-main .bg-img {
    width: 100%;
  }
}

/*
List partners page: End
*/
/*
Modal Video: Start
*/
.js-video-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  transition: all ease 0.5s;
  z-index: -1;
}
.js-video-modal .js-video-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  height: 500px;
  padding-left: 32px;
  padding-right: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.js-video-modal .js-video-modal-container .video-block {
  width: 100%;
  height: 100%;
}
.js-video-modal .js-video-modal-container .video-block iframe {
  width: 100%;
  height: 100%;
  border: none;
}
@media (max-width: 767.99px) {
  .js-video-modal .js-video-modal-container {
    height: 400px;
  }
}
@media (max-width: 576.99px) {
  .js-video-modal .js-video-modal-container {
    height: 320px;
  }
}
@media (max-width: 479.99px) {
  .js-video-modal .js-video-modal-container {
    height: 250px;
  }
}
@media (max-width: 400.99px) {
  .js-video-modal .js-video-modal-container {
    height: 200px;
  }
}

.js-video-modal.open {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}

/*
Modal Video: End
*/
/*
Shared Animation Filter Item: Start
*/
@keyframes showItem {
  from {
    transform: scale(0.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.item-filter.hide {
  display: none;
}

.item-filter.show {
  display: block;
  animation: showItem ease-in-out 0.5s;
}

.row.blog-item.item-filter.show {
  display: flex;
  animation: showItem ease-in-out 0.5s;
}

/*
Shared Animation Filter Item: End
*/
/*
Shared Layout some sections: Start
*/
.layout-item .row .bg-video {
  width: 100%;
  position: relative;
}
.layout-item .row .bg-video i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  color: var(--critical);
  transition: all ease 0.4s;
  padding: 26px;
}
.layout-item .row .bg-video i:hover {
  background-color: rgb(252, 63, 63);
  color: var(--white);
}
@media (max-width: 991.99px) {
  .layout-item .row > div:first-child {
    padding-right: 15px;
  }
  .layout-item .row > div:last-child {
    padding-left: 15px;
  }
}

@media (max-width: 991.99px) {
  .style-three .layout-item .container .row {
    flex-direction: column-reverse;
  }
}
@media (max-width: 575.99px) {
  .style-three .layout-item .container .row {
    flex-direction: column-reverse;
  }
  .style-three .layout-item .container .row > div:nth-child(2) .bg-video i {
    font-size: 24px;
    padding: 18px;
  }
}
/*
Shared Layout some sections: End
*/
/*
Shared section partners: Start
*/
.partner-five-col {
  overflow: hidden;
}
.partner-five-col .list {
  display: flex;
  width: 3120px;
  overflow: hidden;
  animation: scroll 30s linear infinite;
}
.partner-five-col .list .bg-img {
  width: 140px !important;
  margin: 0 60px;
}

/*
Change bg color section partners: Home2
*/
.style-two .partner-five-col {
  background-color: #173363;
  margin-top: 0;
}

/*
Change bg color section partners: Home4
*/
.style-four .partner-five-col {
  background-color: #15143B;
  margin-top: 0;
}

/*
Change bg color section partners: Home5
*/
.style-five .partner-five-col {
  background-color: var(--on-surface);
  margin-top: 60px;
}

/*
Change bg color section partners: Home6
*/
.style-six .partner-five-col {
  background-color: var(--on-surface);
  margin-top: 0;
}

/*
scroll auto
*/
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-1560px);
  }
}
/*
Share section Partners: End
*/
/*
Scroll To Top Button: Start
*/
.scroll-to-top-btn {
  background-color: var(--blue);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  right: 30px;
  transition: all ease 0.3s;
  box-shadow: 0px 5px 25px rgba(58, 64, 67, 0.2);
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
.scroll-to-top-btn i {
  color: var(--white);
  transition: all ease 0.3s;
}
.scroll-to-top-btn:hover {
  background-color: var(--on-surface);
}

.scroll-to-top-btn.active {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}

/*
Change background Color Scroll To Top Button Home3
*/
.style-three .scroll-to-top-btn {
  background-color: var(--gradient);
}
.style-three .scroll-to-top-btn:hover {
  background-color: var(--on-surface);
}

/*
Change background Color Scroll To Top Button Home4
*/
.style-four .scroll-to-top-btn {
  background-color: var(--success);
}
.style-four .scroll-to-top-btn:hover {
  background-color: var(--on-surface);
}

/*
Change background Color Scroll To Top Button Home6
*/
.style-six .scroll-to-top-btn {
  background-color: var(--orange);
}
.style-six .scroll-to-top-btn:hover {
  background-color: var(--white);
}
.style-six .scroll-to-top-btn:hover i {
  color: var(--orange);
}

/*
Scroll To Top Button: End
*/
/*
Form contact main: Start
*/
.form-cta-block {
  position: relative;
  height: 514px;
}
.form-cta-block .bg-img img {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}
.form-cta-block .container > .row > div:last-child {
  display: flex;
  justify-content: flex-end;
}
.form-cta-block .container > .row > div:last-child .form-block {
  width: 78%;
}
.form-cta-block .container > .row > div:last-child .form-block input,
.form-cta-block .container > .row > div:last-child .form-block select,
.form-cta-block .container > .row > div:last-child .form-block textarea {
  outline: none;
  border: 2px solid transparent;
}
.form-cta-block .container > .row > div:last-child .form-block input:focus,
.form-cta-block .container > .row > div:last-child .form-block select:focus,
.form-cta-block .container > .row > div:last-child .form-block textarea:focus {
  border: 2px solid var(--blue);
}
.form-cta-block .container > .row > div:last-child .form-block select {
  -webkit-appearance: none;
}
.form-cta-block .container > .row > div:last-child .form-block .col-12 {
  position: relative;
}
.form-cta-block .container > .row > div:last-child .form-block .col-12 i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
}
.form-cta-block .container > .row > div:last-child .form-block button {
  border: none;
  outline: none;
  transition: 0.3s;
}
.form-cta-block .container > .row > div:last-child .form-block button:hover {
  background-color: var(--white);
  color: var(--blue);
}

@media (max-width: 991.99px) {
  .form-cta-block {
    height: 680px;
  }
  .form-cta-block .container > .row {
    padding-top: 30px;
    padding-bottom: 36px;
  }
  .form-cta-block .container > .row > div:first-child div br {
    display: none;
  }
  .form-cta-block .container > .row > div:last-child {
    justify-content: unset;
  }
  .form-cta-block .container > .row > div:last-child .form-block {
    width: 100%;
  }
}
@media (max-width: 991.99px) {
  .form-cta-block {
    height: 520px;
  }
}
@media (max-width: 575.99px) {
  .form-cta-block {
    height: 580px;
  }
}
@media (max-width: 400.99px) {
  .form-cta-block {
    height: 620px;
  }
}
/*
Form contact main: End
*/
/*
Form contact Home6: Start
*/
.style-six .form-cta-block {
  position: relative;
}
.style-six .form-cta-block .bg-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.style-six .form-cta-block .bg-img img {
  position: fixed;
}
.style-six .form-cta-block .form-block {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
}
.style-six .form-cta-block .button-block button {
  background-color: var(--orange);
  border: none !important;
}
.style-six .form-cta-block .button-block button:hover {
  background-color: var(--white) !important;
  color: var(--orange) !important;
  border: none !important;
}
@media (max-width: 991.99px) {
  .style-six .form-cta-block {
    height: 630px;
  }
  .style-six .form-cta-block .container > .row {
    padding-top: 20px;
    padding-bottom: 24px;
  }
}
@media (max-width: 767.99px) {
  .style-six .form-cta-block {
    height: 520px;
  }
}
@media (max-width: 575.99px) {
  .style-six .form-cta-block {
    height: 580px;
  }
}
@media (max-width: 396.99px) {
  .style-six .form-cta-block {
    height: 600px;
  }
}

/*
Form contact Home6: End
*/
/*
CTA Home2: Start
*/
.cta-block.style-two {
  position: relative;
  height: 120px;
  width: 100%;
}
.cta-block.style-two .bg-cta {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.cta-block.style-two .bg-cta img {
  object-fit: cover;
  width: 100%;
}

/*
CTA Home2: End
*/
/*
CTA Home3: Start
*/
.cta-three .container,
.cta-three .row {
  position: relative;
  height: 100%;
}
.cta-three .button-block {
  padding-bottom: 216px;
  z-index: 5;
}
.cta-three .bg-img {
  position: absolute;
  bottom: 0;
}
.cta-three .bg-img img {
  display: block;
}
@media (max-width: 575.99px) {
  .cta-three .pt-80 {
    padding-top: 40px;
  }
  .cta-three .button-block {
    padding-bottom: 140px;
  }
}
@media (max-width: 399.99px) {
  .cta-three .button-block {
    padding-bottom: 120px;
  }
}

/*
CTA Home3: End
*/
/*
Section CTA: Take control of your financial future - Home4 (Start)
*/
.cta-block.style-four {
  position: relative;
  width: 100%;
}
.cta-block.style-four .bg-cta {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.cta-block.style-four .bg-cta img {
  object-fit: cover;
}
.cta-block.style-four .container > div, .cta-block.style-four .container a {
  z-index: 1;
}

/*
Section CTA: Take control of your financial future - Home4 (End)
*/
/*
Section CTA: Empower Your Finances with Cryptocurrency - Home5 (Start)
*/
.cta-block.style-five .container .content {
  position: relative;
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
}
.cta-block.style-five .container .content .bg-cta {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.cta-block.style-five .container .content .bg-cta img {
  object-fit: cover;
}
.cta-block.style-five .container .content .text {
  padding-right: 230px;
}
@media (max-width: 1169.99px) {
  .cta-block.style-five .container .content .text {
    padding-right: 260px;
    padding-left: 40px;
  }
}
@media (max-width: 900.99px) {
  .cta-block.style-five .container .content .text {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 170px;
    padding-left: 40px;
  }
}
@media (max-width: 767.99px) {
  .cta-block.style-five .container .content .bg-cta img {
    width: 200%;
  }
  .cta-block.style-five .container .content .text {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media (max-width: 479.99px) {
  .cta-block.style-five .container .content .text {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }
  .cta-block.style-five .container .content .button-block {
    flex-direction: column;
    align-items: flex-start;
  }
}

/*
Section CTA: Empower Your Finances with Cryptocurrency - Home5 (End)
*/
/*
Footer: Start
*/
#footer .footer-block .row {
  align-items: flex-start;
}
#footer .footer-block .row .footer-logo {
  width: 145px;
}
#footer .footer-block .row .list-social .item {
  width: 40px;
  height: 40px;
}
#footer .footer-block .row .list-social .item i {
  font-size: 14px;
  margin-left: 2px;
}
#footer .footer-block .row .company-contact .send-block {
  height: 46px;
}
#footer .footer-block .row .company-contact .send-block input {
  height: 100%;
  width: calc(100% - 46px);
  padding: 0 12px;
  border: 1px solid transparent;
  outline: none;
  border-radius: 8px 0 0 8px;
}
#footer .footer-block .row .company-contact .send-block input:focus {
  border: 1px solid var(--blue);
}
#footer .footer-block .row .company-contact .send-block button {
  height: 100%;
  width: 46px;
  border: 1px solid transparent;
  outline: none;
  background-color: var(--blue);
  border-radius: 0 8px 8px 0;
}
#footer .footer-block .row .company-contact .send-block button:hover {
  background-color: #4d67fa;
}
#footer .style-two .footer-block {
  background-color: #173363;
}
#footer .style-three .footer-block {
  background-color: #130E38;
}
#footer .style-three .footer-block .company-contact svg path:nth-child(2),
#footer .style-three .footer-block .company-contact svg path:nth-child(3) {
  fill: var(--gradient) !important;
}
#footer .style-three .footer-block .company-contact svg path:nth-child(4) {
  fill: var(--white) !important;
}
#footer .style-three .footer-block .company-contact .send-block button {
  background-color: var(--gradient);
}
#footer .style-three .footer-block .company-contact .send-block button:hover {
  background-color: rgb(50, 52, 201);
}
#footer .style-four .footer-block {
  background-color: var(--deep-purple);
}
#footer .style-four .footer-block .company-contact svg path:nth-child(2),
#footer .style-four .footer-block .company-contact svg path:nth-child(3) {
  fill: var(--success) !important;
}
#footer .style-four .footer-block .company-contact svg path:nth-child(4) {
  fill: var(--white) !important;
}
#footer .style-four .footer-block .company-contact .send-block button {
  background-color: var(--success);
}
#footer .style-four .footer-block .company-contact .send-block button:hover {
  background-color: rgb(52, 197, 83);
}
#footer .style-five .footer-block {
  background-color: var(--on-surface);
}
#footer .style-six .footer-block {
  background-color: var(--on-surface);
}
#footer .style-six .footer-block .company-contact svg path:nth-child(2),
#footer .style-six .footer-block .company-contact svg path:nth-child(3) {
  fill: var(--orange) !important;
}
#footer .style-six .footer-block .company-contact svg path:nth-child(4) {
  fill: var(--white) !important;
}
#footer .style-six .footer-block .company-contact .send-block button {
  background-color: var(--orange);
}
#footer .style-six .footer-block .company-contact .send-block button:hover {
  background-color: rgb(255, 94, 0);
}

/*
Responsive Footer: Start
*/
@media (max-width: 1023.99px) {
  #footer .cta-block {
    height: 140px;
  }
  #footer .cta-block .container {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
  }
  #footer .footer-block .row .col-3:first-child {
    max-width: 35%;
    flex: 0 0 35%;
  }
  #footer .footer-block .row .col-6 {
    max-width: 55%;
    flex: 0 0 55%;
  }
  #footer .footer-block .row .col-6 .footer-navigate {
    gap: 0;
    justify-content: space-between;
  }
  #footer .footer-block .row .col-3:last-child {
    margin-top: 30px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 767.99px) {
  #footer .cta-block {
    height: 180px;
  }
  #footer .cta-block .container {
    text-align: center;
  }
  #footer .footer-block .row .col-3:first-child {
    flex: 0 0 80%;
    max-width: 80%;
  }
  #footer .footer-block .row .col-6 {
    margin-top: 30px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  #footer .footer-block .row .col-3:last-child {
    flex: 0 0 80%;
    max-width: 80%;
  }
  #footer .footer-block .footer-bottom {
    flex-direction: column-reverse;
    gap: 8px;
  }
}
@media (max-width: 575.99px) {
  #footer .footer-block .row .col-3:first-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
  #footer .footer-block .row .col-6 {
    margin-top: 30px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  #footer .footer-block .row .col-6 .footer-navigate {
    flex-wrap: wrap;
    gap: 30px;
  }
  #footer .footer-block .row .col-6 .footer-nav-item {
    width: 100%;
  }
  #footer .footer-block .row .col-3:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*
Responsive Footer: End
*/
/*
Footer: End
*/
/*
Reduce font and spacing: Start 
*/
@media (max-width: 767.99px) {
  html,
  body {
    font-size: 14px;
    line-height: 22px;
  }
  .heading2 {
    font-size: 28px;
    line-height: 36px;
  }
  .heading3 {
    font-size: 24px;
    line-height: 32px;
  }
  .heading4 {
    font-size: 22px;
    line-height: 30px;
  }
  .heading5 {
    font-size: 20px;
    line-height: 26px;
  }
  .heading6 {
    font-size: 18px;
    line-height: 20px;
  }
  .heading7 {
    font-size: 18px;
    line-height: 20px;
  }
  .text-button {
    font-size: 14px;
    line-height: 20px;
  }
  .text-button-small {
    font-size: 12px;
    line-height: 18px;
  }
  .body1 {
    font-size: 18px;
    line-height: 26px;
  }
  .body2 {
    font-size: 16px;
    line-height: 24px;
  }
  .body3 {
    font-size: 14px;
    line-height: 20px;
  }
  .caption1 {
    font-size: 12px;
    line-height: 16px;
  }
  .button-share {
    padding: 10px 24px;
  }
  .mt-100 {
    margin-top: 60px;
  }
  .pt-100 {
    padding-top: 60px;
  }
  .pt-80 {
    padding-top: 40px;
  }
  .pb-80 {
    padding-bottom: 40px;
  }
  .pt-60 {
    padding-top: 32px;
  }
  .pb-60 {
    padding-bottom: 32px;
  }
  .pt-32 {
    padding-top: 20px;
  }
  .pb-32 {
    padding-bottom: 20px;
  }
  .pt-16 {
    padding-top: 12px;
  }
  .pb-16 {
    padding-bottom: 12px;
  }
  .pb-100 {
    padding-bottom: 60px;
  }
  .mb-100 {
    margin-bottom: 60px;
  }
  .mt-80 {
    margin-top: 28px;
  }
  .mt-60 {
    margin-top: 24px;
  }
  .mt-40 {
    margin-top: 20px;
  }
  .mt-32 {
    margin-top: 16px;
  }
  .mt-24 {
    margin-top: 16px;
  }
  .mt-20 {
    margin-top: 14px;
  }
  .mt-16 {
    margin-top: 12px;
  }
  .mt-12 {
    margin-top: 6px;
  }
  .mt-8 {
    margin-top: 6px;
  }
  .row-gap-60 {
    row-gap: 30px;
  }
  .row-gap-40 {
    row-gap: 20px;
  }
  .row-gap-32 {
    row-gap: 16px;
  }
  .row-gap-24 {
    row-gap: 12px;
  }
  .row-gap-20 {
    row-gap: 10px;
  }
  .gap-30 {
    gap: 20px;
  }
  .gap-24 {
    gap: 16px;
  }
  .gap-20 {
    gap: 14px;
  }
  .gap-16 {
    gap: 12px;
  }
  .gap-12 {
    gap: 8px;
  }
  .p-40 {
    padding: 30px;
  }
  .p-32 {
    padding: 24px;
  }
  .p-24 {
    padding: 16px;
  }
  i.fs-60 {
    font-size: 44px;
  }
  i.fs-52 {
    font-size: 38px;
  }
  i.fs-48 {
    font-size: 36px;
  }
}
/*
Reduce font and spacing: End
*/