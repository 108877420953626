/* styles.css */
.form-control {
    margin-bottom: 1.5rem;
  }
  
  .input {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    width: 100%;
  }
  
  .textarea {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    width: 100%;
    min-height: 150px;
  }
  
  .btn-submit {
    background-color: #3182ce;
    color: white;
    border: none;
    border-radius: 0.25rem;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-submit:hover {
    background-color: #2c5282;
  }
  